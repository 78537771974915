import { useRef, useEffect, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Themes } from 'md-styled-components';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { getIsDirectUser, getIsPortalUser } from 'selectors/users';
import { useLocation } from 'react-router-dom';
import { isPrivateHospital } from 'utils/privateSite';
import { ViewportContext } from '../contexts/ViewportProvider';
import { common } from '../constants';

const {
  xs: xsScreenSize,
  smMax: smMaxScreenSize,
  md: mdScreenSize,
  mdMax: mdMaxScreenSize,
  lg: lgScreenSize,
  xl: xlScreenSize,
} = Themes.breakpoints;

const { mdsaveBaseUrl } = common;

export const useWindowSize = () => {
  const { width, height } = useContext(ViewportContext);
  return { width, height };
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useBreakpoint = (min, max = null) => {
  const size = useWindowSize();
  return (!min || size.width > min) && (!max || size.width < max);
};

export const useIsMobile = () => useBreakpoint(xsScreenSize, mdScreenSize);
export const useIsTablet = () => useBreakpoint(smMaxScreenSize, lgScreenSize);
export const useTillTablet = () => useBreakpoint(xsScreenSize, lgScreenSize);
export const useIsTabletLg = () => useBreakpoint(mdMaxScreenSize, xlScreenSize);
export const useTillTabletLg = () => useBreakpoint(xsScreenSize, xlScreenSize);
export const useIsDesktop = () => useBreakpoint(lgScreenSize);
export const useIsDesktopLg = () => useBreakpoint(xlScreenSize - 1);
export const useTillDesktop = () => useBreakpoint(xsScreenSize, xlScreenSize);

export const useCanonicalURL = () => {
  const { search } = useLocation();
  return `${mdsaveBaseUrl}${window.location.pathname}${search}`;
};

export const useAmpURL = () =>
  `${mdsaveBaseUrl}/amp${window.location.pathname}`;

export const useFixedNavsOffset = (topOffset = 0) => {
  const isIpad = useBreakpoint(smMaxScreenSize, lgScreenSize);
  const isMobile = useIsMobile();
  const isTillTablet = useTillTablet();
  const isDirectUser = useSelector(getIsDirectUser);
  const isPortalUser = useSelector(getIsPortalUser);
  const offsetTop = isTillTablet
    ? common.navOffsetTopTillTablet
    : common.navOffsetTop;

  return useMemo(
    () =>
      ((isPrivateHospital() &&
        ((isIpad && common.hospitalNavOffsetTopIpad) ||
          common.hospitalNavOffsetTop)) ||
        offsetTop) +
      (isDirectUser || isPortalUser ? common.directNavOffsetTop : 0) +
      topOffset,
    [isIpad, offsetTop, isDirectUser, isPortalUser, topOffset]
  );
};

export const usePageChange = (location) => {
  const { privateHospitalSlug } = useSelector(getPrivateHospitalData);
  const paths = location.pathname.split('/');
  const prevlocation = usePrevious(paths[1]);
  const specialtyUrl = location.state?.specialtyUrl;
  const disableScroll = location.state?.disableScroll;
  const specialtyUrlPaths = specialtyUrl ? specialtyUrl.split('/') : [];
  const isSpecialty = specialtyUrlPaths[1]
    ? prevlocation === specialtyUrlPaths[1] ||
      specialtyUrlPaths[1] === privateHospitalSlug
    : false;
  return isSpecialty || disableScroll;
};

export const useUnload = (fn) => {
  const cb = useRef(fn);
  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [cb]);
};
