import { makeAction, makeRequestAction } from '.';

export const requestSuggestedHospitals = makeRequestAction(
  'SUGGESTED_HOSPITALS',
  {
    onSuccess(params, response) {
      return {
        suggestedHospitals: response.data,
      };
    },
  }
);

export const clearSuggestedHospitals = makeAction('CLEAR_SUGGESTED_HOSPITALS');
