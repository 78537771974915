import client from './client';
import { tokens } from '../constants';

export const getTokenApi = () =>
  client()
    .get('/api/getToken')
    .then(async (response) => {
      const { token } = response.data;
      localStorage.setItem(tokens.JWT_TOKEN, token);
      return token;
    });
