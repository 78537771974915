import { ApolloProvider } from '@apollo/client';
import React, { useEffect, lazy, Suspense } from 'react';
import { useLocation, Routes, Route, useNavigate } from 'react-router-dom';
import { pageRoutes } from 'constants/index';
import { LiveChat } from 'components/PageComponents/LiveChat';
import categoryRoutes from 'pages/Category/routes';
import adminRoutes from 'pages/AdminTools/routes';
import superAdminRoutes from 'pages/SuperAdmin/routes';
import procedurePageRoutes from 'pages/Procedure/routes';
import regionalProcedureRoutes from 'pages/RegionalProcedure/routes';
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';
import SignInRoute from 'routes/SignInRoute';
import { isPrivateHospital } from 'utils/privateSite';
import { client } from 'services/contentful';
import AlertProvider from 'contexts/AlertProvider';
import ThirdPartyScripts from 'components/ThirdPartyScripts';
import { useDispatch } from 'react-redux';
import { clearAnalyticData } from 'actions/analyticData';
import DeductibleRoute from './Deductible';
import ExperimentRoute from '../components/ExperimentRoute';

const EmailSignup = lazy(() => import('pages/EmailSignup'));
const SignUp = lazy(() => import('pages/SignUp'));
const SignIn = lazy(() => import('pages/SignIn'));
const ActivateAccount = lazy(() => import('pages/ActivateAccount'));
const ForgotPassword = lazy(() =>
  import('pages/PasswordRecovery/ForgotPassword')
);
const PasswordRecovery = lazy(() =>
  import('pages/PasswordRecovery/PasswordRecovery')
);
const Learn = lazy(() => import('pages/Learn'));
const MPPX = lazy(() => import('pages/MPPX'));
const ParseUrl = lazy(() => import('./ParseUrl'));
const RenderErrorPage = lazy(() => import('./RenderErrorPage'));
const Mammogram = lazy(() => import('pages/Mammogram'));
const SearchResult = lazy(() => import('pages/SearchResult'));
const HeartHealth = lazy(() => import('pages/HeartHealth'));
const WhatIsMdsave = lazy(() => import('pages/Training/WhatIsMdsave'));
const RegisterEhr = lazy(() => import('pages/Training/RegisterEhr'));
const WhenToOffer = lazy(() => import('pages/Training/WhenToOffer'));
const HowToPurchase = lazy(() => import('pages/Training/HowToPurchase'));
const CarePackages = lazy(() => import('pages/Training/CarePackages'));
const WaysToPay = lazy(() => import('pages/Training/WaysToPay'));
const PatientDeductible = lazy(() =>
  import('pages/Training/PatientDeductible')
);
const FinancingTraining = lazy(() => import('pages/Training/Financing'));
const PurchaseScenerio = lazy(() =>
  import('pages/Training/ScenerioTraining/Purchase')
);
const RedemptionScenerio = lazy(() =>
  import('pages/Training/ScenerioTraining/Redemption')
);
const TrainingMain = lazy(() => import('pages/Training'));
const FaqMain = lazy(() => import('pages/FAQ'));
const ResourceCenter = lazy(() => import('pages/Blog/ResourceCenter'));
const BlogMain = lazy(() => import('pages/Blog/Main'));
const BlogSingle = lazy(() => import('pages/Blog/SinglePost'));
const BlogTags = lazy(() => import('pages/Blog/BlogTag'));
const YourInsurance = lazy(() => import('pages/YourInsurance'));
const Financing = lazy(() => import('pages/Financing'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const PostPurchase = lazy(() => import('pages/PostPurchase'));
const CarePackage = lazy(() => import('pages/CarePackage/route'));
const EmailSubscription = lazy(() => import('pages/EmailPreferences'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const Invoice = lazy(() => import('pages/Invoice'));
const DirectPatientCarts = lazy(() => import('pages/Direct/PatientCarts'));
const PortalPatientCarts = lazy(() => import('pages/Portal/PatientCarts'));
const DeductibleCheckerGroups = lazy(() =>
  import('pages/Direct/DeductibleChecker')
);
const DeductibleChecker = lazy(() =>
  import('routes/Deductible/DeductibleChecker')
);
const PatientProfile = lazy(() => import('pages/PatientProfile'));
const Patients = lazy(() => import('pages/Patients'));
const DirectReport = lazy(() => import('pages/Direct/Report'));
const Home = lazy(() => import('pages/Home'));
const ProvidersCampaign = lazy(() => import('pages/ProvidersCampaign'));
const Careers = lazy(() => import('pages/Careers'));
const FaqPrivateLabel = lazy(() => import('pages/FAQ/PrivateLabel'));
const ProviderSearch = lazy(() => import('pages/ProviderSearch'));
const AtoZ = lazy(() => import('pages/AtoZ'));
const Colonoscopy = lazy(() => import('pages/Colonoscopy'));
const GroupUsersPage = lazy(() => import('pages/Group/GroupUsers'));
const Error = lazy(() => import('pages/Error'));
const VouchersPage = lazy(() => import('pages/Group/Vouchers'));
const Provider = lazy(() => import('pages/Provider'));
const View = lazy(() => import('pages/Group/View'));
const Checkout = lazy(() => import('pages/Checkout'));
const Employers = lazy(() => import('pages/Employers'));

const AppRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  if (window.env.IS_PROXY) {
    navigate(0);
  }

  useEffect(() => {
    document.body.style.touchAction = 'auto';

    dispatch(clearAnalyticData());
    return () => {
      window.lastLocation = pathname;
    };
  }, [dispatch, pathname]);

  return (
    <Suspense fallback={<></>}>
      <LiveChat />
      <AlertProvider>
        <ThirdPartyScripts />
        <Routes>
          <Route element={<RenderErrorPage />}>
            <Route
              index
              element={
                isPrivateHospital() ? (
                  <ParseUrl />
                ) : (
                  <ApolloProvider client={client}>
                    <Home />
                  </ApolloProvider>
                )
              }
            />
            <Route path='providers' element={<ProvidersCampaign />} />
            <Route element={<SignInRoute />}>
              <Route path='signin' element={<SignIn />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path='signup/*' element={<SignUp />} />
              <Route path='activateUser' element={<ActivateAccount />} />
              <Route path='activateUser/:code' element={<ActivateAccount />} />
              <Route path='forgotPassword' element={<ForgotPassword />} />
              <Route path='passwordRecovery' element={<PasswordRecovery />} />
              <Route
                path='passwordRecovery/:code'
                element={<PasswordRecovery />}
              />
            </Route>
            <Route path='email' element={<EmailSignup />} />
            <Route path='learn' element={<Learn />} />
            <Route
              path='p/:providerUri/:procedureUri/:mppxId'
              element={<MPPX />}
            />
            <Route
              path='mammogram'
              element={
                <ApolloProvider client={client}>
                  <Mammogram />
                </ApolloProvider>
              }
            />
            <Route
              path='f/procedure/:procedureUri/:zipCode'
              element={<SearchResult />}
            />
            <Route
              path='cardiac-ct'
              element={
                <ApolloProvider client={client}>
                  <HeartHealth />
                </ApolloProvider>
              }
            />
            <Route
              path='colorectal-cancer-awareness'
              element={
                <ApolloProvider client={client}>
                  <Colonoscopy />
                </ApolloProvider>
              }
            />
            <Route path={pageRoutes.A_TO_Z_PROCEDURES_URL} element={<AtoZ />} />
            <Route path={pageRoutes.A_TO_Z_PROVIDERS_URL} element={<AtoZ />} />
            <Route path={pageRoutes.A_TO_Z_HOSPITALS_URL} element={<AtoZ />} />
            <Route
              path='careers'
              element={
                <ApolloProvider client={client}>
                  <Careers />
                </ApolloProvider>
              }
            />

            <Route path='checkout' element={<Checkout />} />
            <Route path='checkout/:authKey?' element={<Checkout />} />

            <Route element={<PrivateRoute />}>
              <Route path='training' element={<TrainingMain />} />
              <Route
                path='training-what-is-mdsave'
                element={<WhatIsMdsave />}
              />
              <Route path='training-when-to-offer' element={<WhenToOffer />} />
              <Route path='training-register-ehr' element={<RegisterEhr />} />
              <Route
                path='training-how-to-purchase'
                element={<HowToPurchase />}
              />
              <Route
                path='training-care-packages'
                element={
                  <ApolloProvider client={client}>
                    <CarePackages />
                  </ApolloProvider>
                }
              />
              <Route path='training-ways-to-pay' element={<WaysToPay />} />
              <Route
                path='training-patient-deductible'
                element={<PatientDeductible />}
              />
              <Route
                path='training-financing'
                element={<FinancingTraining />}
              />
              <Route
                path='training-purchase-scenario'
                element={
                  <ApolloProvider client={client}>
                    <PurchaseScenerio />
                  </ApolloProvider>
                }
              />
              <Route
                path='training-redemption-scenario'
                element={
                  <ApolloProvider client={client}>
                    <RedemptionScenerio />
                  </ApolloProvider>
                }
              />
              <Route path='groups/reportPage' element={<DirectReport />} />
              <Route
                path='groups/savedCarts'
                element={<DirectPatientCarts />}
              />
              <Route
                path='company/savedCarts'
                element={<PortalPatientCarts />}
              />
              <Route path='groups/groupUsers' element={<GroupUsersPage />} />
              <Route path='groups/vouchers' element={<VouchersPage />} />
              <Route path='company/invoicePage' element={<Invoice />} />
              <Route path='groups' element={<View />} />
              <Route path='groups/passive' element={<View />} />
            </Route>
            <Route
              path='groups/deductibleChecker'
              element={<DeductibleCheckerGroups />}
            />
            {procedurePageRoutes()}
            {regionalProcedureRoutes()}
            {adminRoutes()}
            {superAdminRoutes()}
            <Route element={<DeductibleRoute />}>
              <Route
                path='whats-my-health-insurance-deductible'
                element={<DeductibleChecker />}
              />
            </Route>
            <Route
              path='faq'
              element={
                <ApolloProvider client={client}>
                  {isPrivateHospital() ? <FaqPrivateLabel /> : <FaqMain />}
                </ApolloProvider>
              }
            />
            <Route
              path='media'
              element={
                <ApolloProvider client={client}>
                  <ResourceCenter />
                </ApolloProvider>
              }
            />
            <Route
              path='blog'
              element={
                <ApolloProvider client={client}>
                  <BlogMain />
                </ApolloProvider>
              }
            />
            <Route
              path='media/category/:slug'
              element={
                <ApolloProvider client={client}>
                  <BlogTags />
                </ApolloProvider>
              }
            />
            <Route
              path='blog/category/:slug'
              element={
                <ApolloProvider client={client}>
                  <BlogTags />
                </ApolloProvider>
              }
            />
            <Route
              path='media/:slug'
              element={
                <ApolloProvider client={client}>
                  <BlogSingle />
                </ApolloProvider>
              }
            />
            <Route
              path='blog/:slug'
              element={
                <ApolloProvider client={client}>
                  <BlogSingle />
                </ApolloProvider>
              }
            />
            <Route
              path='mdsave-and-your-insurance'
              element={
                <ApolloProvider client={client}>
                  <YourInsurance />
                </ApolloProvider>
              }
            />
            <Route path='financing' element={<Financing />} />
            <Route
              path='about-us'
              element={
                <ApolloProvider client={client}>
                  <AboutUs />
                </ApolloProvider>
              }
            />

            <Route
              path='carepackage/:authKey/*'
              element={
                <ApolloProvider client={client}>
                  <CarePackage />
                </ApolloProvider>
              }
            />
            <Route
              path='email-preferences/:emailSettingData'
              element={<EmailSubscription />}
            />
            {categoryRoutes()}
            <Route path='public/showVoucher' element={<PostPurchase />} />
            <Route
              path='contactus'
              element={
                <ApolloProvider client={client}>
                  <ContactUs />
                </ApolloProvider>
              }
            />
            <Route
              path={pageRoutes.PATIENTS_URL}
              element={
                <ApolloProvider client={client}>
                  <Patients />
                </ApolloProvider>
              }
            />
            <Route path='customer/update' element={<PatientProfile />} />
            <Route path='search/provider' element={<ProviderSearch />} />
            <Route path='404' element={<Error />} />
            <Route path=':page/*' element={<ParseUrl />} />
            <Route path='p/:providerUri' element={<Provider />} />
            <Route path='employers' element={<Employers />} />
          </Route>
        </Routes>
      </AlertProvider>
    </Suspense>
  );
};

export default React.memo(AppRoutes);
