import { addAnalyticData } from 'redux/actions/analyticData';
import { ANALYTICS_MEASUREMENT_ID } from 'config/env-vars';

export const TrackPage = (
  {
    pageName = '',
    pageCategory = '',
    pageProperty = [],
    privateHospitalData = {},
    userId = null,
  },
  dispatch
) => {
  const { analytics, gtag } = window;

  let site = 'Marketplace';
  let hospital = null;
  if (privateHospitalData.privateHospitalName) {
    site = 'Private Label';
    hospital = privateHospitalData.privateHospitalName;
  }
  const analyticsProperties = {
    url: document.location.href,
    path: document.location.pathname,
    site,
    hospital,
    ...pageProperty,
  };

  switch (true) {
    case !!(pageCategory && pageName):
      analytics.page(pageCategory, pageName, analyticsProperties);
      break;
    case !!pageName:
      analytics.page(pageName, analyticsProperties);
      break;
    default:
      analytics.page(analyticsProperties);
  }
  console.log('analyticsProperties', analyticsProperties);
  const settings = {
    page_path: analyticsProperties.path,
    page_location: analyticsProperties.url,
    site: analyticsProperties.site,
    hospital: analyticsProperties.hospital,
    send_page_view: false,
  };

  if (pageName) {
    settings.page_title = pageName;
  }
  if (pageCategory) {
    settings.content_group = pageCategory;
  }

  if (analyticsProperties.referrer) {
    settings.referrer = analyticsProperties.referrer;
  }

  settings.user_id = userId || analytics.user().anonymousId();
  gtag('config', ANALYTICS_MEASUREMENT_ID, settings);
  gtag('event', 'page_view', analyticsProperties);

  dispatch(addAnalyticData({ data: { pageDataSent: true } }));
};

export const Track = (text, analyticsProperties = {}) => {
  const { analytics } = window;
  analytics.ready(() => {
    analytics.track(text, analyticsProperties);
  });
};

export const TrackTransparencySearch = (url = '', location, trackData) => {
  if (
    url.replace('/', '') !==
    `${location.pathname}${location.search}`.replace('/', '')
  ) {
    Track('Transparency Procedure Searched', trackData);
  }
};

export const TrackLink = (itemRef, name, { label, category }) => {
  const { analytics } = window;
  analytics.trackLink(itemRef, name, {
    label,
    category,
  });
};
