import { ApolloClient, InMemoryCache } from '@apollo/client';
import {
  APP_CONTENTFUL_SPACE_ID,
  APP_CONTENTFUL_CDA_TOKEN,
  APP_CONTENTFUL_ENVIRONMENT_NAME,
  APP_CONTENTFUL_CPA_TOKEN,
  APP_DEPLOY_ENVIRONMENT,
} from '../config/env-vars';

const token =
  APP_DEPLOY_ENVIRONMENT === 'master'
    ? APP_CONTENTFUL_CDA_TOKEN
    : APP_CONTENTFUL_CPA_TOKEN;
export const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${APP_CONTENTFUL_SPACE_ID}/environments/${APP_CONTENTFUL_ENVIRONMENT_NAME}`,
  cache: new InMemoryCache(),
  errorPolicy: 'all',
  headers: {
    authorization: `Bearer ${token}`,
  },
});
