import { isIOS } from 'react-device-detect';

export const disableFields = () => {
  if (isIOS) {
    for (const el of document.querySelectorAll(
      '#root form input:not([disabled]), #root form textarea:not([disabled])'
    )) {
      el.classList.add('disable-field');
      el.setAttribute('disabled', 'disabled');
    }
  }
};

export const enableFields = () => {
  for (const el of document.querySelectorAll('#root .disable-field')) {
    el.classList.remove('disable-field');
    el.removeAttribute('disabled');
  }
};
