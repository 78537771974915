import {
  requestGetFeaturedSpecialties,
  clearGetFeaturedSpecialties,
  requestSystemLocations,
  clearSystemLocations,
  requestGetImageFullPath,
  requestGetGroupsMapData,
  requestGetAllSpecialties,
  requestShareCart,
  requestGroupUserHospitalList,
  requestGroupUserAllHospitalsApi,
  requestGroupUserAccountManager,
  requestGroupLogo,
  clearGroupLogo,
} from 'actions/groups';

export default (
  state = {
    featuredSpecialties: [],
    systemLocations: {},
    providerImages: {},
    allSpecialties: [],
    shareCart: null,
    hospitalList: [],
    allHospitals: {
      systemList: {},
      thirdPartyName: '',
    },
    groupsMapData: {},
    groupLogo: '',
    accountManager: null,
  },
  action
) => {
  switch (action.type) {
    case requestGetFeaturedSpecialties.requestTypes.SUCCESS:
      return {
        ...state,
        featuredSpecialties: action.response.featuredSpecialties,
      };
    case requestSystemLocations.requestTypes.SUCCESS:
      return { ...state, systemLocations: action.systemLocations };
    case clearGetFeaturedSpecialties.actionName:
      return {
        ...state,
        featuredSpecialties: [],
      };
    case clearSystemLocations.actionName:
      return {
        ...state,
        systemLocations: {},
      };
    case requestGetImageFullPath.requestTypes.REQUEST:
      return {
        ...state,
        providerImages: {
          ...state.providerImages,
          [action.src]: { time: Date.now() },
        },
      };
    case requestGetImageFullPath.requestTypes.SUCCESS:
      return {
        ...state,
        providerImages: {
          ...state.providerImages,
          [action.src]: { url: action[action.src], time: Date.now() },
        },
      };
    case requestGetImageFullPath.requestTypes.FAILURE:
      return {
        ...state,
        providerImages: { ...state.providerImages, [action.src]: null },
      };
    case requestGetGroupsMapData.requestTypes.SUCCESS:
      return {
        ...state,
        groupsMapData: action.response.groupsMapData,
      };
    case requestGetAllSpecialties.requestTypes.SUCCESS:
      return {
        ...state,
        allSpecialties: action.allSpecialties,
      };
    case requestShareCart.requestTypes.SUCCESS:
      return {
        ...state,
        shareCart: action.shareCart,
      };
    case requestGroupUserHospitalList.requestTypes.SUCCESS:
      return {
        ...state,
        hospitalList: action.hospitalList,
      };
    case requestGroupLogo.requestTypes.SUCCESS:
      return {
        ...state,
        groupLogo: action.groupLogo,
      };
    case clearGroupLogo.actionName:
      return {
        ...state,
        groupLogo: {},
      };
    case requestGroupUserAllHospitalsApi.requestTypes.SUCCESS:
      return {
        ...state,
        allHospitals: action.allHospitals,
      };
    case requestGroupUserAccountManager.requestTypes.SUCCESS:
      return {
        ...state,
        accountManager: action.accountManager,
      };
    default:
      return state;
  }
};
