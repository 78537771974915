import { transparency } from './queries';
import client from '../services/apolloClient';

export const getInsuranceProvidersApi = ({ params }) =>
  client
    .query({
      query: transparency.searchInsuranceProvidersQuery(params),
      variables: {
        query: {
          hospitalInputs: Array.isArray(params.groupId)
            ? params.groupId.map((item) => ({ id: item }))
            : [{ id: params.groupId }],
          name: params.data,
        },
      },
    })
    .then((response) => response)
    .catch((error) => error);
