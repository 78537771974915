import { takeLeading, takeEvery } from 'redux-saga/effects';
import {
  requestGetItems,
  removeItem,
  requestChangeQty,
  requestClearCart,
  requestSaveGroupUserData,
  requestSetNote,
  requestPutItem,
} from 'actions/shopping-cart';
import {
  removeItemApi,
  getItemsApi,
  changeQtyApi,
  saveGroupUserData,
  removeCart,
  setNote,
  putItemApi,
} from 'services/shopping-cart';

export const watchLoadGetItems = { ...requestGetItems, api: getItemsApi };
export const watchLoadRemoveItem = {
  ...removeItem,
  api: removeItemApi,
  take: takeLeading,
};
export const watchLoadChangeQty = { ...requestChangeQty, api: changeQtyApi };
export const watchLoadClearItems = { ...requestClearCart, api: removeCart };
export const watchSaveGroupUserData = {
  ...requestSaveGroupUserData,
  api: saveGroupUserData,
};
export const watchSetNote = { ...requestSetNote, api: setNote };
export const watchLoadPutItem = {
  ...requestPutItem,
  api: putItemApi,
  take: takeEvery,
};
