import client from './client';

export const getOrderInfo = ({ params }) => {
  const searchParams = new URLSearchParams(params.search);
  const [key] = Object.keys(Object.fromEntries(searchParams.entries()));

  return client().get(`/api/vouchers/getOrderWith${key}${params.search}`);
};

export const getMedicalProvider = ({ params }) =>
  client().get(`/api/vouchers/getProvider${params.search}`);

export const getProcedures = ({ params }) =>
  client().post('/api/vouchers/getProcedures', params);

export const getTransactionAmounts = ({ params }) =>
  client().post('/api/vouchers/transactionAmounts', params);

export const getPdfLinks = ({ params }) =>
  client().get(`/api/vouchers/getPdfLinks${params.search}`);

export const getPatientInfo = ({ params }) =>
  client().get(`/api/vouchers/getPatientInfo?customerId=${params.customerId}`);

export const getRegisterFormData = ({ lastTransaction }) =>
  client().post('/api/vouchers/getRegisterFormData', { lastTransaction });

export const getReferralData = ({ params }) =>
  client().get(
    `/api/vouchers/getReferralData?customerId=${params.customerId}&revenue=${params.revenue}`
  );
