import { makeRequestAction } from '.';

export const requestGetState = makeRequestAction('GET_STATE', {
  onSuccess(params, response) {
    return {
      state: response.data,
    };
  },
});

export const requestGetStateUrl = makeRequestAction('GET_STATE_URL', {
  onSuccess(params, response) {
    return {
      state: response.data,
    };
  },
});
