import React from 'react';
import { render } from 'react-dom';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import { Themes, ThemeProvider } from 'md-styled-components';
import App from './App';
import store from './config/configureStore';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

render(
  <ThemeProvider theme={Themes}>
    <App store={store} />
  </ThemeProvider>,
  rootElement
);

if (module.hot) {
  module.hot.accept();
}

if (process.env.REACT_APP_MOCK_SERVER === 'true') {
  // eslint-disable-next-line global-require
  const { worker } = require('./__mocks__/browser');
  worker.start();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
