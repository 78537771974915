import { clearCarePackage } from 'actions/carePackage';
import entitiesMerge from './entitiesMerge';

const defaultState = {
  staticContent: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case clearCarePackage.actionName: {
      return defaultState;
    }
    default:
      return entitiesMerge(state, action, 'carePackage');
  }
};
