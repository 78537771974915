import React from 'react';
import { useSelector } from 'react-redux';
import { getIsReportUser, getIsGroupUser, getCurrentUser, getIsGuestUser } from 'selectors/users';
import { Outlet, Navigate } from 'react-router-dom';

const SignInRoute = () => {
    const isGroupUser = useSelector(getIsGroupUser);
    const isReportUser = useSelector(getIsReportUser);
    const isGuest = useSelector(getIsGuestUser);
    const currentUser = useSelector(getCurrentUser);
    if (currentUser) {
        switch (true) {
            case isGroupUser:
                window.location.href = `/groups/dashboard2?GroupId=${currentUser?.groupInfo?.GroupId}`
                break
            case isReportUser:
                return <Navigate to='/groups/reportPage' replace />
            case isGuest:
                return <Outlet />
            default:
                return <Navigate to='/' replace />
        }
    }
    return null;
};

export default SignInRoute;
