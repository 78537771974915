import client from './client';

export const getGetCurrentUserApi = ({ params }) =>
  client().get('/api/publicSite/GetCurrentUser', { params });

export const getSubscribeApi = ({ params }) =>
  client().post('/subscribe', params);

export const getTabsByUserTypeApi = ({ params }) =>
  client().post('/public/getTabsByUserType', params);

export const getCompanyUserCompaniesApi = ({ params }) =>
  client().post('/companyUsers/getCompanyUserCompanies', params);

export const checkUserByPersonalInformationApi = ({ params }) =>
  client().post('/public/checkUserByPersonalInformation', params);

export const getCurrentUserRankApi = (params) =>
  client().get(`/api/groupsApi/getCurrentUserRank?type=${params.value}`);
