const MTIProjectId = 'e2e55bf7-e7c4-4598-944f-c27d0d977313';
const supportEmail = 'customeroperations@tendo.com';
const supportTeam = 'Healthcare Support Team';
const supportPhoneNumber = '(877) 461-2491';
const workHours = 'Available Monday–Friday from 7am to 5pm CT';
const searchMinLength = 2;
const searchMaxLength = 150;
const whiteLabelHospital = 'WLHospital';
const directNavbar = 'direct';
const cmsPage = 'cms-page';
const groupPage = 'group-page';
const employers = 'employers';
const navOffsetTop = 80;
const navSearchOffsetTop = 128;
const navOffsetTopTillTablet = 64;
const directNavOffsetTop = 40;
const hospitalNavOffsetTop = 120;
const hospitalNavOffsetTopIpad = 100;
const faqNavOffsetTop = 128;
const procedureOffsetTop = 128;
const resourceCenterOffsetTop = 128;
const resourceCenterOffsetTopTablet = 60;
const STICKY_TABS_HEIGHT = 56;
const carePackagePage = 'carePackage';
const providersPage = 'providers';
const unitedStateUrl = 'united-states';
const otherRegionName = 'Other Regions';
const signOutUrl = 'logout';
const payerTypeExcluded = 4;
const searchClearBtnClassName = 'clear-search';
const searchBtnClassName = 'search-btn';
const mdsaveBaseUrl = window.location.origin;
const MDSAVE = 'MDsave';
const REVIEWS_ANSWERS_COUNT = 5;
const REVIEWS_ANSWERS_MIN = 3;
const LOCATION_TYPE_PRACTICE = 2;
const PAYMENT_TEAM_EMAIL = 'customeroperations@tendo.com';
const MARKETING_TEAM_EMAIL = 'marketing@mdsave.com';
const GROUP_USER_MAX_TABS_COUNT = 8;
const PREGNANCY_ID = 9;
const CT_SCAN_ID = 5;
const COLONOSCOPY_ID = 100;
const NEW_YEAR_ID = 10;
const ONE_CALL_ID = 1457;
const bbCmsUrl = 'site';
const cmsHome = 'Home';
const GFE_TITLE = 'Good Faith Estimate';
const notAvailable = 'Not available';
const CAREERS_TENDO_URL = 'https://tendo.com/open-roles/';

const heroSearchFields = {
  proceduresField: 'Procedures',
  providersField: 'Providers',
  hospitalsField: 'Hospitals',
  insuranceProviderField: 'InsuranceProvider',
};
const headerSearchFields = {
  proceduresField: 'Procedures-header',
  providersField: 'Providers-header',
  hospitalsField: 'Hospitals-header',
  insuranceProviderField: 'InsuranceProvider-header',
};

const transparencyChargeTypes = {
  1: 'Facility Fee',
  2: 'Physician',
  3: 'Anesthesia',
  4: 'Laboratory',
  5: 'Radiology',
  6: 'Pathology',
};

export default {
  supportEmail,
  supportTeam,
  headerSearchFields,
  heroSearchFields,
  searchMinLength,
  searchMaxLength,
  supportPhoneNumber,
  workHours,
  MTIProjectId,
  whiteLabelHospital,
  employers,
  navOffsetTop,
  navSearchOffsetTop,
  navOffsetTopTillTablet,
  directNavOffsetTop,
  hospitalNavOffsetTop,
  hospitalNavOffsetTopIpad,
  faqNavOffsetTop,
  resourceCenterOffsetTop,
  resourceCenterOffsetTopTablet,
  procedureOffsetTop,
  unitedStateUrl,
  carePackagePage,
  providersPage,
  otherRegionName,
  signOutUrl,
  transparencyChargeTypes,
  payerTypeExcluded,
  searchClearBtnClassName,
  searchBtnClassName,
  mdsaveBaseUrl,
  MDSAVE,
  REVIEWS_ANSWERS_COUNT,
  REVIEWS_ANSWERS_MIN,
  LOCATION_TYPE_PRACTICE,
  PAYMENT_TEAM_EMAIL,
  MARKETING_TEAM_EMAIL,
  GROUP_USER_MAX_TABS_COUNT,
  directNavbar,
  cmsPage,
  groupPage,
  PREGNANCY_ID,
  CT_SCAN_ID,
  COLONOSCOPY_ID,
  NEW_YEAR_ID,
  ONE_CALL_ID,
  bbCmsUrl,
  cmsHome,
  GFE_TITLE,
  notAvailable,
  STICKY_TABS_HEIGHT,
  CAREERS_TENDO_URL,
};
