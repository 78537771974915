import { makeAction, makeRequestAction, action } from '.';
import { checkNotFoundPage } from '../../utils/checkNotFoundPage';

export const requestCarePackage = makeRequestAction('GET_CARE_PACKAGE', {
  onSuccess(params, response) {
    checkNotFoundPage(response);
    return {
      response: {
        carePackage: response.data,
      },
    };
  },
});

export const getStaticContent = (data) =>
  action('GET_STATIC_CONTENT', {
    response: {
      carePackage: {
        staticContent: data?.staticContent.items[0],
      },
    },
  });

export const clearCarePackage = makeAction('CLEAR_CARE_PACKAGE');
