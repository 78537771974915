export default (
  state = {
    modalVisible: false,
    showPersonalDataFields: true,
    onlineChat: false,
    questionType: null,
  },
  action
) => {
  switch (action.type) {
    case 'SHOW_CONTACT_US_MODAL':
      return {
        ...state,
        modalVisible: action.modalVisible,
        showPersonalDataFields: action.showPersonalDataFields,
        questionType: action.questionType,
      };
    case 'SHOW_CHAT_LINKS':
      return {
        ...state,
        onlineChat: action.onlineChat,
      };
    default:
      return state;
  }
};
