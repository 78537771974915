import {
  requestForgotPassword,
  requestResetPassword,
  requestCheckCode,
  requestClearCode,
  requestGetUserEmail,
  requestPasswordRecovery,
} from 'actions/passwordRecovery';

import {
  getForgotPasswordApi,
  getResetPasswordApi,
  getCheckCodeApi,
  getClearCodeApi,
  getUserEmailApi,
  getPasswordRecoveryApi,
} from 'services/passwordRecovery';

export const watchLoadForgotPassword = {
  ...requestForgotPassword,
  api: getForgotPasswordApi,
};

export const watchLoadResetPassword = {
  ...requestResetPassword,
  api: getResetPasswordApi,
};

export const watchCheckCode = {
  ...requestCheckCode,
  api: getCheckCodeApi,
};

export const watchClearCode = {
  ...requestClearCode,
  api: getClearCodeApi,
};

export const watchUserEmail = {
  ...requestGetUserEmail,
  api: getUserEmailApi,
};

export const watchLoadPasswordRecovery = {
  ...requestPasswordRecovery,
  api: getPasswordRecoveryApi,
};
