import { makeRequestAction } from './index';

export const requestCampaignProcedures = makeRequestAction(
  'CAMPAIGN_PROCEDURES',
  {
    onSuccess(params, response) {
      return {
        response: {
          campaign: {
            procedures: response,
          },
        },
      };
    },
  }
);

export const requestCampaignAllProviders = makeRequestAction(
  'CAMPAIGN_ALL_PROVIDERS',
  {
    onSuccess(params, response) {
      return {
        response: {
          campaign: {
            allProviders: response,
          },
        },
      };
    },
  }
);
