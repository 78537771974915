import { addFlags } from 'actions/featureFlags';
import entitiesMerge from './entitiesMerge';

const featureFlags = () => {
  const initialState = {
    flags: {
      checkoutPage: 'Angular',
    },
    loading: true,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case addFlags.actionName: {
        return {
          ...state,
          flags: action.params.flags,
          loading: false,
        };
      }
      default:
        return entitiesMerge(state, action, 'featureFlags');
    }
  };
};

export default featureFlags();
