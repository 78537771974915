import { SET_LOGIN_MODAL_VISIBLE } from 'actions/actionTypes';

const loginModal = () => {
  const initialState = {
    visible: false,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case SET_LOGIN_MODAL_VISIBLE:
        return {
          ...state,
          visible: action.data,
        };
      default:
        return state;
    }
  };
};

export default loginModal();
