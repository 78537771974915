import { debounce } from 'redux-saga/effects';
import {
  requestLocationInfo,
  requestRegionalProcedure,
  requestRegionalLocations,
  requestRegionalProviders,
  requestAllRegionalProviders,
  requestEstimatedPrices,
} from 'actions/regionalProcedure';
import {
  getLocationInfoApi,
  getRegionalProcedureApi,
  getRegionalLocationsApi,
  getRegionalProvidersApi,
  getAllRegionalProvidersApi,
  getEstimatedPricesApi,
} from 'services/regionalProcedure';

export const watchLoadLocationInfo = {
  ...requestLocationInfo,
  api: getLocationInfoApi,
  take: debounce,
  ms: 500,
};

export const watchLoadRegionalProcedure = {
  ...requestRegionalProcedure,
  api: getRegionalProcedureApi,
  take: debounce,
  ms: 500,
};

export const watchLoadRegionalLocations = {
  ...requestRegionalLocations,
  api: getRegionalLocationsApi,
  take: debounce,
  ms: 500,
};

export const watchLoadRegionalProviders = {
  ...requestRegionalProviders,
  api: getRegionalProvidersApi,
  take: debounce,
  ms: 500,
};

export const watchLoadAllRegionalProviders = {
  ...requestAllRegionalProviders,
  api: getAllRegionalProvidersApi,
  take: debounce,
  ms: 500,
};

export const watchLoadEstimatedPrices = {
  ...requestEstimatedPrices,
  api: getEstimatedPricesApi,
  take: debounce,
  ms: 500,
};
