import {
  requestCampaignProcedures,
  requestCampaignAllProviders,
} from 'actions/campaign';
import {
  getCampaignProceduresApi,
  getCampaignAllProvidersApi,
} from 'services/campaign';

export const watchLoadMammogram = {
  ...requestCampaignProcedures,
  api: getCampaignProceduresApi,
};

export const watchLoadMammogramProviders = {
  ...requestCampaignAllProviders,
  api: getCampaignAllProvidersApi,
};
