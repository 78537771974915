import { getMailChimpMemberEmail, requestPageInstance } from 'actions/support';
import {
  getMailChimpMemberEmailApi,
  getPageInstanceApi,
} from 'services/support';

export const watchMailChimpMemberEmail = {
  ...getMailChimpMemberEmail,
  api: getMailChimpMemberEmailApi,
};

export const watchPageInstance = {
  ...requestPageInstance,
  api: getPageInstanceApi,
};
