import React from 'react';
import { useSelector } from 'react-redux';
import { getIsGuestUser } from 'selectors/users';
import { Outlet, Navigate } from 'react-router-dom';

const PublicRoute = () => {
  const isGuest = useSelector(getIsGuestUser);

  return isGuest ? <Outlet /> : <Navigate to='/' replace />;
};

export default PublicRoute;
