import {
  requestSuggestedHospitals,
  clearSuggestedHospitals,
} from 'actions/hospitals';

export default (
  state = {
    suggestedHospitals: null,
  },
  action
) => {
  switch (action.type) {
    case requestSuggestedHospitals.requestTypes.SUCCESS:
      return {
        ...state,
        suggestedHospitals: action.suggestedHospitals,
      };
    case clearSuggestedHospitals.actionName:
      return {
        ...state,
        suggestedHospitals: null,
      };
    default:
      return state;
  }
};
