import {
  requestSuggestedProviderHospitals,
  clearSuggestedProviderHospitals,
} from 'actions/providerHospitals';

export default (
  state = {
    suggestedProviderHospitals: null,
  },
  action
) => {
  switch (action.type) {
    case requestSuggestedProviderHospitals.requestTypes.SUCCESS:
      return {
        ...state,
        suggestedProviderHospitals: action.suggestedProviderHospitals.data,
      };
    case clearSuggestedProviderHospitals.actionName:
      return {
        ...state,
        suggestedProviderHospitals: null,
      };
    default:
      return state;
  }
};
