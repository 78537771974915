import Theme from 'md-styled-components/Themes';

export default `
&&&{
  background-color: transparent;
  color: ${Theme.white};
  box-shadow: ${Theme['btn-shadow-inset']} ${Theme['border-width-base']} ${Theme.white};
  
  &:hover {
    background-color: ${Theme.white};
    color: ${Theme['primary-cta']};
    path {
      fill: ${Theme['primary-cta']} !important;
    }
  }
  
  &:focus {
    background-color: ${Theme['primary-cta']};
    color: ${Theme.white};
    box-shadow: ${Theme['btn-shadow-inset']} ${Theme['btn-outer-border-size']} ${Theme['primary-cta']},
      ${Theme['btn-shadow-inset']} ${Theme['btn-inner-border-size']} ${Theme.white};
    path {
      fill: ${Theme.white} !important;
    }
  }
  
  &:active {
    background-color: ${Theme.beige};
    color: ${Theme['mdsave-blue']};
    path {
      fill: ${Theme['mdsave-blue']} !important;
    }
  }
 
  &:disabled, &:disabled:hover {
    background-color: ${Theme['white-tertiary-disabled-background']};
    color: ${Theme.I50};
    path {
        fill: ${Theme.I50} !important;
    }
  }
}
`;
