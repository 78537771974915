import { makeRequestAction, action } from '.';

const createActionWithCB = (name, storeName, resName = null) =>
  makeRequestAction(name, {
    onSuccess(params, response) {
      const procedures =
        response.success && (resName ? response.data[resName] : response.data);
      params.cb && params.cb(procedures);
      return {
        response: {
          transparency: {
            [storeName]: procedures,
          },
        },
      };
    },
  });

const requestDetailsWrap = (name) =>
  makeRequestAction(name, {
    onSuccess(params, response) {
      const options = (params && params.params) || undefined;
      return {
        response: response.data.procedureDetails,
        params: options,
      };
    },
  });

export const requestWithoutInsuranceProcedures = createActionWithCB(
  'TRANSPARENCY_WITHOUT_INSURANCE_PROCEDURES',
  'withoutInsuranceProcedures',
  'proceduresList'
);

export const requestWithInsuranceProcedures = createActionWithCB(
  'TRANSPARENCY_WITH_INSURANCE_PROCEDURES',
  'withInsuranceProcedures',
  'proceduresList'
);

export const requestWithInsProcDetails = requestDetailsWrap(
  'TRANSPARENCY_WITH_INSURANCE_PROCEDURE_DETAILS'
);

export const requestWithoutInsProcDetails = requestDetailsWrap(
  'TRANSPARENCY_WITHOUT_INSURANCE_PROCEDURE_DETAILS'
);

export const requestInsuranceProviders = makeRequestAction(
  'INSURANCE_PROVIDERS',
  {
    onSuccess(params, response) {
      return {
        response: {
          transparency: {
            insuranceProviders: response.searchInsuranceProviders,
          },
        },
      };
    },
  }
);

export const requestHasWithoutInsurance = createActionWithCB(
  'TRANSPARENCY_HAS_WITHOUT_INSURANCE',
  'hasWithoutInsurance'
);

export const requestGetProcedureName = makeRequestAction(
  'TRANSPARENCY_GET_PROCEDURE_NAME',
  {
    onSuccess(params, response) {
      return {
        response: {
          transparency: {
            procedureName: response.success && response.data,
          },
        },
      };
    },
  }
);

export const requestGetProviderName = makeRequestAction(
  'TRANSPARENCY_GET_PROVIDER_NAME',
  {
    onSuccess(params, response) {
      return {
        response: {
          transparency: {
            providerName: response.success && response.data,
          },
        },
      };
    },
  }
);

export const requestGetPriceUpdateDate = makeRequestAction(
  'TRANSPARENCY_GET_PRICE_UPDATE_DATE',
  {
    onSuccess(params, response) {
      return {
        response: {
          transparency: {
            priceUpdateDate: response.success && response.data,
          },
        },
      };
    },
  }
);

export const transparencyProcedureDetailsOpen =
  'TRANSPARENCY_PROCEDURE_DETAILS_OPEN';
export const setProcedureDetailsOpen = (data) =>
  action('TRANSPARENCY_PROCEDURE_DETAILS_OPEN', data);
