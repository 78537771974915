import {
  requestGetState,
  requestGetStateUrl,
} from 'actions/geographicLocation';

export default (
  state = {
    state: '',
  },
  action
) => {
  switch (action.type) {
    case requestGetState.requestTypes.SUCCESS:
    case requestGetStateUrl.requestTypes.SUCCESS:
      return {
        ...state,
        state: action.state,
      };
    default:
      return state;
  }
};
