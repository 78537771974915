export const CT_SCAN_ENCODED_IDS = [
  'd781f5cf',
  'd781f5cc',
  'd781f5cd',
  'd781f5c4',
  'd786fccc',
  'd781f5c5',
];

export const FIGHT = 'fight';

export const HEART_SCREENING = 'heartscreening';

export const CAMPAIGN_LINKS = {
  [FIGHT]: '/procedures/2d-mammogram-screening/d786fcc5',
  [HEART_SCREENING]: '/procedures/electrocardiogram-ekg-ecg/d182ff',
};
