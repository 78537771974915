import { takeLatest, takeLeading } from 'redux-saga/effects';
import {
  requestCurrentUser,
  requestSubscribe,
  requestClearSubscribe,
  requestGetTabsByUserType,
  requestCheckUserByPersonalInformation,
  requestCompanyUserCompanies,
} from 'actions/users';

import {
  getGetCurrentUserApi,
  getSubscribeApi,
  getTabsByUserTypeApi,
  checkUserByPersonalInformationApi,
  getCompanyUserCompaniesApi,
} from 'services/users';

export const watchLoadCurrentUser = {
  ...requestCurrentUser,
  api: getGetCurrentUserApi,
};

export const watchLoadCompanyUserCompanies = {
  ...requestCompanyUserCompanies,
  api: getCompanyUserCompaniesApi,
};

export const watchLoadSubscribe = {
  ...requestSubscribe,
  api: getSubscribeApi,
  take: takeLeading,
};

export const watchLoadClearSubscribe = {
  ...requestClearSubscribe,
  api: () => true,
};

export const watchLoadGetTabsByUserType = {
  ...requestGetTabsByUserType,
  api: getTabsByUserTypeApi,
};

export const watchLoadCheckUserByPersonalInformation = {
  ...requestCheckUserByPersonalInformation,
  api: checkUserByPersonalInformationApi,
  take: takeLatest,
};
