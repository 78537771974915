import { makeAction, makeRequestAction } from '.';

const action = (actionName, key) =>
  makeRequestAction(actionName, {
    onSuccess(params, response) {
      const returnData = {
        response: {
          regionalProcedure: {},
        },
      };
      if (response.error) {
        returnData.response.regionalProcedure.error = true;
      } else {
        returnData.response.regionalProcedure[key] = {
          [params.searchParams]: response.data,
        };
      }
      return returnData;
    },
  });

export const requestLocationInfo = action('LOCATION_INFO', 'locationInfo');

export const requestRegionalProcedure = action(
  'REGIONAL_PROCEDURE',
  'procedureInfo'
);

export const requestRegionalLocations = action(
  'REGIONAL_LOCATIONS',
  'locations'
);

export const requestRegionalProviders = action(
  'REGIONAL_PROVIDERS',
  'providers'
);

export const requestAllRegionalProviders = action(
  'ALL_REGIONAL_PROVIDERS',
  'allProviders'
);

export const requestEstimatedPrices = makeRequestAction(
  'ESTIMATED_PRICES',
  {
    onSuccess(params, response) {
      return {
        response: {
          regionalProcedure: {
            estimatedPrices: response.data,
          },
        },
      };
    },
  }
);

export const clearRegionalProcedure = makeAction('CLEAR_REGIONAL_PROCEDURE');
