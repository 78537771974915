import React, { createContext, useEffect, useState } from 'react';
import { debounce } from '../utils/common';

export const ViewportContext = createContext({});

export const ViewportProvider = ({ children }) => {
  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    const handleResize = () => {
      const windowNewSize = getSize();
      if (windowNewSize.width !== windowSize.width) {
        setWindowSize(windowNewSize);
      }
    };
    const resizeEvent = debounce(handleResize);

    window.addEventListener('resize', resizeEvent);

    return () => window.removeEventListener('resize', resizeEvent);
  });

  return (
    <ViewportContext.Provider
      value={{ width: windowSize.width, height: windowSize.height }}
    >
      {children}
    </ViewportContext.Provider>
  );
};
