import entitiesMerge from './entitiesMerge';

export default (
  state = {
    list: null,
    saveCart: null,
    info: null,
  },
  action
) => entitiesMerge(state, action, 'patients');
