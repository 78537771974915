import { createSelector } from 'reselect';

export const liveAgent = (state) => state.liveAgent;

export const getModalVisible = createSelector(
  liveAgent,
  (data) => data.modalVisible
);

export const getQuestionType = createSelector(
  liveAgent,
  (data) => data.questionType
);

export const getChatLink = createSelector(liveAgent, (data) => data.onlineChat);

export const getShowPersonalDataFields = createSelector(
  liveAgent,
  (data) => data.showPersonalDataFields
);
