import { actionTypes } from 'components/ReportTable/constants';
import { clearElectronicLinkData } from 'actions/groups';

const { SET_ELECTRONIC_STATUS } = actionTypes;

const defaultState = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ELECTRONIC_STATUS:
      return {
        ...state,
        ...action.data,
      };
    case clearElectronicLinkData.actionName:
      return defaultState;
    default:
      return state;
  }
};
