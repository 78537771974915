import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { APP_RECAPTCHA_SITE_KEY } from '../../config/env-vars';

export default React.memo(({ children }) => {
  useEffect(() => {
    const reCaptcha = document.getElementById('google-recaptcha-v3');
    reCaptcha && document.querySelector('head').removeChild(reCaptcha);
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={APP_RECAPTCHA_SITE_KEY}
      useEnterprise
    >
      {children}
    </GoogleReCaptchaProvider>
  );
});
