import React from 'react';
import { Icon, Themes } from 'md-styled-components';
import { useBreakpoint as breakpoint } from '../../hooks';
import './index.less';
import { useContactUsModal, useLiveAgent } from './_hooks';

const xsScreenSize = Themes.breakpoints.xs;
const mdScreenSize = Themes.breakpoints.md;

export default ({ online, id, href, ...props }) => {
  let statusText = 'Send Us a Message';
  let statusClassName = 'offline';
  if (online) {
    statusClassName = 'online';
    statusText = 'Live Chat';
  }
  const onClickOnline = useLiveAgent();
  const onClickOffline = useContactUsModal();

  return (
    <a
      className={`mds-live-agent mds-live-agent--anim mds-live-agent--${statusClassName} ${props.className}`}
      href={href}
      id={id}
      onClick={online ? onClickOnline : onClickOffline}
      style={{ display: 'none' }}
    >
      <div className='mds-live-agent__main-wrapper flex-display flex-direction-row flex-align-items-center flex-justify-center'>
        <div className='mds-live-agent__icon'>
          <Icon
            size='16px'
            type={
              breakpoint(xsScreenSize, mdScreenSize)
                ? 'message-mobile'
                : 'message'
            }
            color={Themes.white}
          />
        </div>
        <div className='mds-live-agent__info flex-display flex-direction-column hidden-sm'>
          <span className='mds-live-agent__status'>{statusText}</span>
        </div>
      </div>
    </a>
  );
};
