import { makeAction, makeRequestAction } from '.';

const action = (actionName, key) =>
  makeRequestAction(actionName, {
    onSuccess(params, response) {
      return {
        response: {
          procedures: {
            [key]: response.data,
          },
        },
      };
    },
  });

export const requestSuggestedProcedures = makeRequestAction(
  'SUGGESTED_PROCEDURES',
  {
    onSuccess(params, response) {
      const { cbRedirect } = params;
      cbRedirect && cbRedirect(response.data);
      return {
        suggestedProcedures: response.data,
      };
    },
  }
);

export const requestSuggestedProceduresHeader = makeRequestAction(
  'SUGGESTED_PROCEDURES_HEADER',
  {
    onSuccess(params, response) {
      return {
        suggestedProceduresHeader: response.data,
      };
    },
  }
);

export const requestMDSaveAverage = action(
  'MDSAVE_NATIONAL_AVERAGE',
  'averageOfMDSave'
);

export const requestGetRelatedProcedures = action(
  'GET_RELATED_PROCEDURES',
  'relatedProcedures'
);

export const requestGetProceduresEstimatedPrice = action(
  'GET_PROCEDURES_ESTIMATED_PRICE',
  'proceduresEstimatedPrice'
);

export const requestGetAverageRating = action(
  'GET_PROCEDURE_AVERAGE_RATING',
  'averageRating'
);

export const requestProcedureSurveys = makeRequestAction('PROCEDURE_SURVEYS', {
  onSuccess(params, response) {
    return {
      response: {
        procedures: {
          procedureSurveys: response.data,
          showMoreClickCount: params.showMoreClickCount,
        },
      },
    };
  },
});

export const requestSetSelectedProcedure = makeAction('SET_SELECTED_PROCEDURE');

export const requestGetProvidersList = action(
  'GET_PROVIDERS_LIST',
  'providersList'
);

export const requestGetStructuredData = action(
  'GET_PROCEDURE_STRUCTURED_DATA',
  'structuredData'
);

export const requestProcedure = action('PROCEDURE_MODEL', 'procedure');

export const clearProcedure = makeAction('CLEAR_PROCEDURE_MODEL');

export const clearSuggestedProcedures = makeAction(
  'CLEAR_SUGGESTED_PROCEDURES'
);

export const switchClearSuggestedProcedures = makeAction(
  'SWITCH_CLEAR_SUGGESTED_PROCEDURES'
);

export const clearSuggestedProceduresHeader = makeAction(
  'CLEAR_SUGGESTED_PROCEDURES_HEADER'
);

export const clearProcedureSurveys = makeAction('CLEAR_PROCEDURE_SURVEYS');
