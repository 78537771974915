import { useDispatch, useSelector } from 'react-redux';
import { showContactUsModal } from 'actions/liveAgent';
import { getCurrentUser } from 'selectors/users';
import { getModalVisible } from 'selectors/liveAgent';
import { disableFields } from 'utils/form';
import { Track } from '../../Analytics/Track';
import {
  APP_SFBTNID_KNOWN,
  APP_SFBTNID_UNKNOWN,
} from '../../../config/env-vars';

export const useLiveAgent = () => {
  const currentUser = useSelector(getCurrentUser);
  const userId =
    currentUser &&
    currentUser.firstName &&
    currentUser.lastName &&
    currentUser.id;
  return () => {
    window.liveagent.startChat(
      userId ? APP_SFBTNID_KNOWN : APP_SFBTNID_UNKNOWN
    );
    Track(!userId ? 'Live Chat Opened' : 'Live Chat Conversation Started');
  };
};

export const useContactUsModal = (
  showPersonalDataFields = true,
  questionType
) => {
  const dispatch = useDispatch();
  const contactUsModal = useSelector(getModalVisible);
  return () => {
    dispatch(
      showContactUsModal({
        modalVisible: !contactUsModal,
        showPersonalDataFields,
        questionType,
      })
    );
    disableFields();
    Track('Send Us A Message Opened');
  };
};
