import { common, sesStorage } from '../constants';

const { locationsField, providersField, insuranceProviderField } =
  common.heroSearchFields;

const {
  locationsField: locationsFieldHeader,
  providersField: providersFieldHeader,
  insuranceProviderField: insuranceProviderFieldHeader,
} = common.headerSearchFields;

const { locationKey, providersKey, insuranceProviderKey } = sesStorage;

export const getSesStorageItemKey = (fieldName, group, system) => {
  let key;
  let keyPrefix = '';

  if (system) {
    keyPrefix = system.slug;
  } else if (group && Object.keys(group).length) {
    keyPrefix = group.slug;
  }

  switch (fieldName) {
    case locationsField:
    case locationsFieldHeader:
      key = locationKey;
      break;

    case providersField:
    case providersFieldHeader:
      key = providersKey;
      break;

    case insuranceProviderField:
      key = insuranceProviderKey;
      break;

    case insuranceProviderFieldHeader:
      key = insuranceProviderKey;
      break;

    default:
      break;
  }
  return keyPrefix ? `${keyPrefix}-${key}` : key || '';
};

export const getSesStorageItem = (key) => {
  const item = sessionStorage.getItem(key);
  return item && item !== 'undefined' ? item : '';
};

export const setSesStorageItem = (key, value) =>
  sessionStorage.setItem(key, value);

export const clearSesStorageItem = (key) => sessionStorage.removeItem(key);

export const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  return item && item !== 'undefined' ? item : '';
};

export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value);

export const clearLocalStorageItem = (key) => localStorage.removeItem(key);
