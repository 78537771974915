export const PAGE_LEVEL_STATE = 'state';
export const PAGE_LEVEL_REGION = 'region';
export const PAGE_LEVEL_CITY = 'city';
export const SAVE_AN_AVERAGE = 'Save by purchasing this procedure online.';
export const MAP_UNIQUE_KEY = 'search_address_id';
export const PROVIDERS_PER_PAGE = 10;
export const PRICE_ORDER_KEY = 2;
export const ALPHABETIC_ORDER_KEY = 3;
export const RATING_ORDER_KEY = 4;
export const SORT_BY = [
  { value: PRICE_ORDER_KEY, name: 'Price' },
  { value: ALPHABETIC_ORDER_KEY, name: 'Alphabetic' },
  { value: RATING_ORDER_KEY, name: 'Rating' },
];
export const MAP_SORT_BY_TO_KEYS = {
  [PRICE_ORDER_KEY]: 'total_price',
  [ALPHABETIC_ORDER_KEY]: 'medical_provider_name',
  [RATING_ORDER_KEY]: 'rating_avg',
};
export const MAP_MOBILE_HEIGHT = 465;
export const MAP_SECTION_ID = 'rpp-map-section';
export const MAP_HASH = '#map';
export const DEFAULT_ZOOM = 6;
export const USA_CENTER = {
  center: {
    lng: -101,
    lat: 47,
  },
  zoom: 4,
};
