import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';

const RobotsTxt = lazy(() => import('pages/SuperAdmin/RobotsTxt'));
const SuperAdminRoutes = () => (
  <Route element={<PrivateRoute />}>
    <Route path='/admin/update-robots-txt' element={<RobotsTxt />} />
  </Route>
);

export default SuperAdminRoutes;
