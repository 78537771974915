import {
  requestRegisterPatient,
  requestRegisterProvider,
  requestRegisterProviderValidate,
} from 'actions/sign-up';
import { getSignUpApi, getSignUpProviderApi } from 'services/sign-up';

export const watchLoadRegisterPatient = {
  ...requestRegisterPatient,
  api: getSignUpApi,
};

export const watchLoadRegisterProvider = {
  ...requestRegisterProvider,
  api: getSignUpProviderApi,
};

export const watchLoadRegisterProviderValidate = {
  ...requestRegisterProviderValidate,
  api: getSignUpProviderApi,
};
