import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { scrollTo } from 'utils/common';
import Chat from '../LiveAgent/Chat';
import { APP_SFBTNID_KNOWN, APP_SFBTNID_UNKNOWN } from '../../config/env-vars';
import { usePageChange } from '../../hooks';
import { isChatDisabled } from './helpers';

export const LiveChat = React.memo(() => {
  const location = useLocation();
  const disableScroll = usePageChange(location);
  const { showSingleTab } = useSelector(getPrivateHospitalData);

  const isLiveChatDisabled = useMemo(() => {
    !disableScroll &&
      scrollTo(document.getElementById('root'), false, true, 'auto');
    return isMobileOnly || isChatDisabled(location);
  }, [disableScroll, location]);

  const chatClassName = useMemo(
    () =>
      isLiveChatDisabled || showSingleTab ? 'mds-live-agent__disabled' : '',
    [isLiveChatDisabled, showSingleTab]
  );

  return (
    <div>
      <Chat
        online
        id={`liveagent_button_online_${APP_SFBTNID_KNOWN}`}
        className={chatClassName}
        /* eslint-disable-next-line no-script-url */
        href='javascript://Chat'
      />
      <Chat
        id={`liveagent_button_offline_${APP_SFBTNID_UNKNOWN}`}
        className={chatClassName}
      />
    </div>
  );
});
