import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Skeleton as skeleton } from 'antd';
import Row from '../Row';
import Col from '../Col';
import Themes from '../Themes';

const SkeletonAntd = styled(skeleton)`
  & {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;

    .ant-skeleton-content {
      .ant-skeleton-title,
      .ant-skeleton-paragraph > li {
        border-radius: ${Themes.spacing[3]};
      }
    }

    ${(props) =>
      props &&
      props.align === 'center' &&
      css`
        .ant-skeleton-title,
        .ant-skeleton-paragraph > li {
          margin-right: auto;
          margin-left: auto;
        }
      `}

    ${(props) =>
      props &&
      props.align === 'right' &&
      css`
        .ant-skeleton-title,
        .ant-skeleton-paragraph > li {
          margin-right: 0;
          margin-left: auto;
        }
      `}

    ${(props) =>
      props &&
      props.avatarAlign === 'center' &&
      css`
        .ant-skeleton-header {
          display: table-row;

          .ant-skeleton-avatar {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
          }
        }
      `}

    ${(props) =>
      props &&
      props.avatarBlock &&
      css`
        .ant-skeleton-header {
          .ant-skeleton-avatar {
            display: block;
            width: 100% !important;
          }
        }
      `}

    ${(props) =>
      props &&
      props.type === 'opacity' &&
      css`
        .ant-skeleton-header .ant-skeleton-avatar {
          background: rgb(248 248 245 / 0.5);
        }

        .ant-skeleton-content {
          .ant-skeleton-title,
          .ant-skeleton-paragraph > li {
            background: rgb(248 248 245 / 0.5);
          }
        }
      `}
  }
`;

const SkeletonAntdButton = styled(({ fullWidth, ...props }) => (
  <SkeletonAntd.Button {...props} />
))`
  ${(props) =>
    props.fullWidth &&
    css`
      &.ant-skeleton-element {
        width: 100% !important;
      }
    `}

  .ant-skeleton {
    &-button {
      width: ${(props) => (props.fullWidth ? '100%' : '160px')};
    }
  }
`;

const SkeletonAvatar = styled(SkeletonAntd.Avatar)``;

const Skeleton = (props) => (
  <>
    {props.columns > 1 ? (
      <Row
        gutter={[
          {
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          },
          20,
        ]}
      >
        {new Array(props.columns).fill(1).map((_, i) => (
          <Col span={24 / props.columns} key={i}>
            <SkeletonAntd {...props}>{props.children}</SkeletonAntd>
          </Col>
        ))}
      </Row>
    ) : (
      <SkeletonAntd {...props}>{props.children}</SkeletonAntd>
    )}
  </>
);

Skeleton.Button = SkeletonAntdButton;
Skeleton.Input = SkeletonAntd.Input;
Skeleton.Avatar = SkeletonAvatar;

SkeletonAntdButton.propTypes = {
  fullWidth: propTypes.bool,
};

SkeletonAntdButton.defaultProps = {
  fullWidth: false,
};

Skeleton.propTypes = {
  columns: propTypes.oneOf([1, 2, 3, 4, 6]),
  align: propTypes.oneOf(['left', 'center', 'right']),
  avatarAlign: propTypes.oneOf(['left', 'center']),
  avatarBlock: propTypes.bool,
  type: propTypes.oneOf(['opacity', 'default']),
};

Skeleton.defaultProps = {
  align: 'left',
  columns: 1,
  avatarAlign: 'left',
};

export default Skeleton;
