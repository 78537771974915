import Cookies from 'js-cookie';

export const setCookie = (name, value) => {
  Cookies.set(name, value);
};

export const getCookie = (name) => Cookies.get(name);

export const getDecodeCookie = (name) =>
  Cookies.get(name) && decodeURIComponent(Cookies.get(name));

export const removeCookie = (name) => {
  Cookies.remove(name);
};
