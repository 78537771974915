import { makeAction, makeRequestAction } from '.';

export const requestProviderInfo = makeRequestAction('GET_PROVIDER_INFO', {
  onSuccess(params, response) {
    return {
      response: {
        mppx: {
          providerInfo: {
            [params.providerUri || params.providerEncodedId]: response.data,
          },
        },
      },
    };
  },
});

export const requestCurrentProcedure = makeRequestAction(
  'GET_CURRENT_PROCEDURE',
  {
    onSuccess(params, response) {
      return {
        response: {
          mppx: {
            currentProcedure: {
              [params.mppxId]: response.data,
            },
          },
        },
      };
    },
  }
);

export const requestVisitProcedures = makeRequestAction(
  'GET_VISIT_PROCEDURES',
  {
    onSuccess(params, response) {
      return {
        response: {
          mppx: {
            visitProcedures: response,
          },
        },
      };
    },
  }
);

export const requestAdditionalProcedures = makeRequestAction(
  'GET_ADDITIONAL_PROCEDURE',
  {
    onSuccess(params, response) {
      return {
        response: {
          mppx: {
            additionalProcedures: response.data,
          },
        },
      };
    },
  }
);

export const requestPartyData = makeRequestAction('GET_PARTY_DATA', {
  onSuccess(params, response) {
    return {
      response,
      mppxId: params.mppxId,
    };
  },
});

export const requestProviderReviews = makeRequestAction(
  'GET_PROVIDER_REVIEWS',
  {
    onSuccess(params, response) {
      return {
        response: {
          [params.showMoreClickCount ? 'additionalReviews' : 'providerReviews']:
            {
              [params.providerId]: response,
            },
          providerId: params.providerId,
        },
      };
    },
  }
);

export const requestEquivalentProcedures = makeRequestAction(
  'GET_EQUIVALENT_PROCEDURES',
  {
    onSuccess(params, response) {
      return {
        response,
      };
    },
  }
);

export const clearCurrentProcedure = makeAction('CLEAR_CURRENT_PROCEDURE');

export const clearProviderReviews = makeAction('CLEAR_PROVIDER_REVIEWS');

export const clearProviderInfo = makeAction('CLEAR_PROVIDER_INFO');
