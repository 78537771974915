import { debounce } from 'redux-saga/effects';
import { requestSuggestedProviderHospitals } from 'actions/providerHospitals';
import { getSuggestedProviderHospitalsApi } from 'services/providerHospitals';

export const watchLoadSuggestedProviderHospitals = {
  ...requestSuggestedProviderHospitals,
  api: getSuggestedProviderHospitalsApi,
  take: debounce,
  ms: 500,
};
