import {
  requestProcedures,
  requestProviders,
  requestOrderInfo,
  requestPdfLinks,
  requestPatientInfo,
  requestTransactionAmounts,
  clearPostPurchaseState,
} from 'actions/postPurchase';

const defaultState = {
  orderInfo: {
    transactions: [],
    voucherVisitInfo: [],
    orderSummaryInfo: {
      orders: [],
      ids: [],
      providers: [],
    },
    trackingData: {},
    redirectUrl: null,
  },
  medicalProvider: null,
  procedures: null,
  pdfLinks: null,
  patient: null,
  amounts: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case requestOrderInfo.requestTypes.SUCCESS: {
      return {
        ...state,
        orderInfo: { ...defaultState.orderInfo, ...action.response.orderInfo },
      };
    }
    case requestProviders.requestTypes.SUCCESS: {
      return {
        ...state,
        medicalProvider: {
          ...state.medicalProvider,
          [action.response.medicalProvider.id]: action.response.medicalProvider,
        },
      };
    }
    case requestProcedures.requestTypes.SUCCESS: {
      return {
        ...state,
        procedures: {
          ...state.procedures,
          [action.response.uniqueKey]: action.response.purchasedProcedures,
        },
      };
    }
    case requestPdfLinks.requestTypes.SUCCESS: {
      return {
        ...state,
        pdfLinks: action.response.pdfLinks,
      };
    }
    case requestPatientInfo.requestTypes.SUCCESS: {
      return {
        ...state,
        patient: action.response.patient,
      };
    }
    case requestTransactionAmounts.requestTypes.SUCCESS: {
      return {
        ...state,
        amounts: action.response.amounts,
      };
    }
    case clearPostPurchaseState.actionName: {
      return { ...state, ...defaultState };
    }
    default:
      return state;
  }
};
