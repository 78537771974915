import privateSiteTypes from 'constants/privateSite';

export const isAllHospitals = (type) => type === privateSiteTypes.ALL_HOSPITALS;

export const isPrivateHospital = () =>
  !!window.location.host.match(
    '^((?!(staging|redox|demo|clearance|security|testing|www|proxy*|backend*)).*mdsave(?!:3000).*)' +
      '|(www.(?!(staging|redox|demo|clearance|security|testing|mdsave)).*mdsave.*)' +
      '|(www.mdsave.(?!(staging|redox|demo|clearance|security|testing|mdsave|com)).*)'
  );
