import { takeEvery } from 'redux-saga/effects';
import {
  requestProviderInfo,
  requestCurrentProcedure,
  requestAdditionalProcedures,
  requestProviderReviews,
  requestPartyData,
  requestVisitProcedures,
  requestEquivalentProcedures,
} from 'actions/mppx';

import {
  getProviderInfoApi,
  getCurrentProcedureApi,
  getAdditionalProceduresApi,
  getProviderReviews,
  getPartyData,
  getVisitProceduresApi,
  getEquivalentProceduresApi,
} from 'services/mppx';

export const watchLoadGetProviderInfoApi = {
  ...requestProviderInfo,
  api: getProviderInfoApi,
  take: takeEvery,
};
export const watchLoadGetCurrentProcedureApi = {
  ...requestCurrentProcedure,
  api: getCurrentProcedureApi,
  take: takeEvery,
};
export const watchLoadGetAdditionalProceduresApi = {
  ...requestAdditionalProcedures,
  api: getAdditionalProceduresApi,
};
export const watchLoadGetProviderReviewsApi = {
  ...requestProviderReviews,
  api: getProviderReviews,
  take: takeEvery,
};
export const watchLoadGetPartyDataApi = {
  ...requestPartyData,
  api: getPartyData,
  take: takeEvery,
};
export const watchLoadGetVisitProceduresApi = {
  ...requestVisitProcedures,
  api: getVisitProceduresApi,
  take: takeEvery,
};
export const watchLoadGetEquivalentProceduresApi = {
  ...requestEquivalentProcedures,
  api: getEquivalentProceduresApi,
  take: takeEvery,
};
