import { makeRequestAction } from '.';

export const requestTopProcedures = makeRequestAction('TOP_PROCEDURES', {
  onSuccess(params, response) {
    return {
      response: {
        topProcedures: {
          topProcedures: response,
        },
      },
    };
  },
});
