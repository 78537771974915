import Theme from 'md-styled-components/Themes';

export default `
&&&{
  background-color: transparent;
  color: ${Theme['primary-cta']};
  box-shadow: ${Theme['btn-shadow-inset']} ${Theme['border-width-base']} ${Theme['primary-cta']};
  path {
    fill: ${Theme['primary-cta']} !important;
  }
  
  &:hover {
    background-color: ${Theme['primary-cta']};
    color: ${Theme.white};
    path {
      fill: ${Theme.white} !important;
    }
  }
  
  &:focus {
    background-color: ${Theme['primary-cta']};
    color: ${Theme.white};
    box-shadow: ${Theme['btn-shadow-inset']} ${Theme['btn-outer-border-size']} ${Theme['primary-cta']},
      ${Theme['btn-shadow-inset']} ${Theme['btn-inner-border-size']} ${Theme.white};
    path {
      fill: ${Theme.white} !important;
    }
  }
  
  &:active {
    background-color: ${Theme['mdsave-blue']};
    color: ${Theme.white};
  }
 
  &:disabled, &:disabled:hover{
    background-color: ${Theme.white};
    color: ${Theme.I50};
    box-shadow: ${Theme['btn-shadow-inset']} ${Theme['border-width-base']} ${Theme.I10};
    path {
      fill: ${Theme.I50} !important;
    }
  }
}
`;
