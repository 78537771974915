import { makeAction, makeRequestAction } from '.';

const action = (actionName, key) =>
  makeRequestAction(actionName, {
    onSuccess(params, response) {
      return {
        response: {
          [key]: response.data,
        },
      };
    },
  });

export const requestOrderInfo = action('GET_ORDER_INFO', 'orderInfo');

export const requestProviders = action('GET_PROVIDERS', 'medicalProvider');

export const requestProcedures = makeRequestAction('GET_PROCEDURES', {
  onSuccess(params, response) {
    return {
      response: {
        purchasedProcedures: response.data,
        uniqueKey: params.uniqueKey,
      },
    };
  },
});

export const clearPostPurchaseState = makeAction('CLEAR_POST_PURCHASE_STATE');

export const requestPdfLinks = action('GET_PDF_LINKS', 'pdfLinks');

export const requestPatientInfo = action('GET_PATIENT_INFO', 'patient');

export const requestTransactionAmounts = action(
  'GET_TRANSACTION_AMOUNTS',
  'amounts'
);
