import client from './client';

export const getSuggestedLocationsApi = ({ params }) =>
  client().post('/public/getLocationsByText', params);

export const getProvidersLocations = ({ params }) =>
  client().get(
    `/api/procedurePage/getProvidersLocations?procedureId=${params.procedureId}`
  );

export const getCityStateByZip = ({ params }) =>
  client().get(`/location/getCityStateByZip?zipCode=${params.zipCode}`);
