import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { pageRoutes } from 'constants/index';
import { ApolloProvider } from '@apollo/client';
import { client } from 'services/contentful';
import {
  PAGE_LEVEL_STATE,
  PAGE_LEVEL_REGION,
  PAGE_LEVEL_CITY,
} from './_constants';

const RegionalProcedure = lazy(() => import('pages/RegionalProcedure'));

const routes = () => (
  <>
    <Route
      path={pageRoutes.REGIONAL_PROCEDURE_STATE}
      element={
        <ApolloProvider client={client}>
          <RegionalProcedure pageLevel={PAGE_LEVEL_STATE} />
        </ApolloProvider>
      }
    />
    <Route
      path={pageRoutes.REGIONAL_PROCEDURE_AREA}
      element={
        <ApolloProvider client={client}>
          <RegionalProcedure pageLevel={PAGE_LEVEL_REGION} />
        </ApolloProvider>
      }
    />
    <Route
      path={pageRoutes.REGIONAL_PROCEDURE_CITY}
      element={
        <ApolloProvider client={client}>
          <RegionalProcedure pageLevel={PAGE_LEVEL_CITY} />
        </ApolloProvider>
      }
    />
  </>
);

export default routes;
