import { debounce } from 'redux-saga/effects';
import {
  requestSuggestedProcedures,
  requestSuggestedProceduresHeader,
  requestMDSaveAverage,
  requestProcedure,
  requestGetRelatedProcedures,
  requestGetAverageRating,
  requestProcedureSurveys,
  requestGetProvidersList,
  requestGetStructuredData,
} from 'actions/procedures';
import {
  getSuggestedProceduresApi,
  getMDSaveAverageApi,
  getProcedureApi,
  getRelatedProceduresApi,
  getGetAverageRatingApi,
  getProcedureSurveysApi,
  getProvidersListApi,
  getStructuredDataApi,
} from 'services/procedures';

export const watchLoadMDSaveAverage = {
  ...requestMDSaveAverage,
  api: getMDSaveAverageApi,
};

export const watchLoadProcedure = { ...requestProcedure, api: getProcedureApi };

export const watchLoadRelatedProcedures = {
  ...requestGetRelatedProcedures,
  api: getRelatedProceduresApi,
};

export const watchLoadAverageRating = {
  ...requestGetAverageRating,
  api: getGetAverageRatingApi,
};

export const watchLoadProcedureSurveys = {
  ...requestProcedureSurveys,
  api: getProcedureSurveysApi,
};

export const watchLoadProvidersList = {
  ...requestGetProvidersList,
  api: getProvidersListApi,
};

export const watchLoadStructuredData = {
  ...requestGetStructuredData,
  api: getStructuredDataApi,
};

export const watchLoadSuggestedProcedures = {
  ...requestSuggestedProcedures,
  api: getSuggestedProceduresApi,
  take: debounce,
  ms: 500,
};

export const watchLoadSuggestedProceduresHeader = {
  ...requestSuggestedProceduresHeader,
  api: getSuggestedProceduresApi,
  take: debounce,
  ms: 500,
};
