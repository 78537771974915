import { makeAction, makeRequestAction } from '.';

export const requestRegisterPatient = makeRequestAction('PATIENT', {
  onSuccess(params, response) {
    return {
      patient: response,
    };
  },
});

export const requestRegisterProvider = makeRequestAction('PROVIDER', {
  onSuccess(params, response) {
    return {
      provider: response,
    };
  },
});

export const requestRegisterProviderValidate = makeRequestAction(
  'PROVIDER_VALIDATE',
  {
    onSuccess(params, response) {
      params.cb &&
        params.cb(response.error && response.data[params.field_name]);
    },
  }
);

export const clearPatient = makeAction('CLEAR_PATIENT');
export const clearProvider = makeAction('CLEAR_PROVIDER');
