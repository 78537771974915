/* eslint-disable no-undef */
import THEME_VARIABLES from '../ThemeProvider/themeVariables.json';

export default {
  ...THEME_VARIABLES,
  'font-family': 'Avenir-Roman, Helvetica, Arial, sans-serif',
  breakpoints: {
    xs: 0,
    smMin: 320,
    sm: 576,
    smMax: 767,
    md: 768,
    mdMax: 991,
    lg: 992,
    xlg: 1024,
    xl: 1200,
    xxl: 1600,
  },
  spacing: {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '1rem',
    4: '2rem',
    5: '3rem',
    6: '4rem',
    7: '5rem',
    8: '6rem',
    9: '7rem',
    10: '1.5rem',
    11: '3.5rem',
    12: '1.75rem',
    13: '0.125rem',
    14: '2.5rem',
    15: '0.75rem',
    auto: 'auto',
  },
};
