import React from 'react';
import { Row as Component } from 'antd';
import propTypes from 'prop-types';

const Row = (props) => <Component {...props} />;

Row.propTypes = {
  /** the vertical alignment of the flex layout: top middle bottom */
  align: propTypes.oneOf(['top', 'middle', 'bottom']),
  /** spacing between grids, could be a number or a object like { xs: 8, sm: 16, md: 24} */
  gutter: propTypes.oneOfType([
    propTypes.number,
    propTypes.object,
    propTypes.array,
  ]),
  /** horizontal arrangement of the flex layout: start end center space-around space-between */
  justify: propTypes.oneOf([
    'start',
    'end',
    'center',
    'space-around',
    'space-between',
    'space-evenly',
  ]),
  /** auto wrap line */
  wrap: propTypes.bool,
};

Row.defaultProps = {
  align: 'top',
  gutter: 0,
  justify: 'start',
};

/** @component */
export default Row;
