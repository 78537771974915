import {
  requestProcedureSurveys,
  clearProcedureSurveys,
  clearProcedure,
  requestSetSelectedProcedure,
  requestProcedure,
} from 'actions/procedures';
import entitiesMerge from './entitiesMerge';

export default (
  state = {
    averageOfMDSave: null,
    procedure: [],
    relatedProcedures: null,
    averageRating: {},
    procedureSurveys: null,
    providersList: [],
    structuredData: {},
    showMoreClickCount: 0,
    selectedProcedure: {},
  },
  action
) => {
  switch (action.type) {
    case requestProcedureSurveys.requestTypes.SUCCESS: {
      const { procedureSurveys, showMoreClickCount } =
        action.response.procedures;
      const { procedureSurveys: prevSurveys } = state;
      return {
        ...state,
        showMoreClickCount,
        procedureSurveys:
          !prevSurveys || !procedureSurveys
            ? procedureSurveys
            : {
                ...prevSurveys,
                answers: [...prevSurveys.answers, ...procedureSurveys.answers],
              },
      };
    }
    case clearProcedureSurveys.actionName:
      return {
        ...state,
        procedureSurveys: null,
        showMoreClickCount: 0,
      };
    case requestSetSelectedProcedure.actionName:
      return {
        ...state,
        selectedProcedure: action.params,
      };
    case clearProcedure.actionName:
    case requestProcedure.requestTypes.REQUEST:
      return {
        ...state,
        procedure: [],
      };
    default:
      return entitiesMerge(state, action, 'procedures');
  }
};
