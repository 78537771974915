import { requestProvidersLocations } from 'actions/location';

export default (
  state = {
    providersLocations: [],
  },
  action
) => {
  switch (action.type) {
    case requestProvidersLocations.requestTypes.SUCCESS:
      return {
        ...state,
        providersLocations: action.providersLocations,
      };
    default:
      return state;
  }
};
