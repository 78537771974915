import decode from 'jwt-decode';
import dayjs from 'dayjs';
import { tokens } from '../../constants';
import { getTokenApi } from '../../services/authToken';

export const getJWT = () => {
  const token = localStorage.getItem(tokens.JWT_TOKEN);
  if (token) {
    try {
      const { exp } = decode(token);
      const currentTime = new Date();
      const expUTC = new Date(1000 * exp);
      const difference = dayjs(
        dayjs(currentTime.toUTCString()).diff(dayjs(expUTC.toUTCString()))
      ).valueOf();
      if (difference > 1000 * 60 * 60) {
        return getTokenApi();
      }
      return token;
    } catch (error) {
      return null;
    }
  }
  return getTokenApi();
};
