import Axios from 'axios';
import { configure } from 'axios-hooks';
import { responseTypes, pageRoutes } from 'constants/index';
import { APP_APTIBLE_URL } from '../config/env-vars';
import initCsrf from '../csrf';

const axios = (headers = {}, params = {}) => {
  const service = Axios.create({
    baseURL: APP_APTIBLE_URL, // url of the api
    withCredentials: true,
    headers: {
      ...headers,
      'React-App': true,
      'X-Requested-With': 'XMLHttpRequest',
    },
    ...params,
  });
  service.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error?.response || { status: null, data: {} };
      if (!data.alertData) {
        switch (status) {
          case responseTypes.NOT_FOUND_STATUS:
            window.location.pathname =
              data.redirectUrl || pageRoutes.NOT_FOUND_URL;
            break;
          case responseTypes.NOT_ALLOWED_STATUS:
            window.location.pathname =
              data.redirectUrl || pageRoutes.SIGN_IN_URL;
            break;
          default:
            break;
        }
      }

      throw error;
    }
  );

  return service;
};

export const redirectToUrl = (url, externalUrl = 0) => {
  window.location.href =
    APP_APTIBLE_URL && !externalUrl ? `${APP_APTIBLE_URL}${url}` : url;
};

configure({ axios: axios() });

export default axios;
