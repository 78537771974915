import { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showChatLinks } from 'actions/liveAgent';
import { getCurrentUser } from 'selectors/users';
import {
  APP_SFBTNID_KNOWN,
  APP_SFBTNDEPLOYID_UNKNOWN,
  APP_SFBTNPATH,
  APP_SFBTNDEPLOYID_KNOWN,
  APP_SFBTNID_UNKNOWN,
  APP_SFDEPLOYID,
} from '../../config/env-vars';

const LiveAgent = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { liveagent } = window;

  useLayoutEffect(() => {
    if (liveagent) {
      liveagent.setChatWindowHeight(385);
      const cookieGA = document.cookie.match(/_ga=(.+?);/);
      const anonymousId = window.analytics.user
        ? window.analytics.user().anonymousId()
        : null;
      if (cookieGA && cookieGA.length) {
        const clientId = cookieGA[1].split('.').slice(-2).join('.');
        clientId && liveagent.addCustomDetail('googleId', clientId);
      }
      anonymousId && liveagent.addCustomDetail('anonymousId', anonymousId);
      liveagent.init(APP_SFBTNPATH, APP_SFBTNDEPLOYID_UNKNOWN, APP_SFDEPLOYID);
      liveagent.init(APP_SFBTNPATH, APP_SFBTNDEPLOYID_KNOWN, APP_SFDEPLOYID);
      // eslint-disable-next-line no-underscore-dangle
      if (!window._laq) {
        // eslint-disable-next-line no-underscore-dangle
        window._laq = [];
      }
      // eslint-disable-next-line no-underscore-dangle
      window._laq.push(() => {
        liveagent.showWhenOffline(
          APP_SFBTNID_UNKNOWN,
          document.getElementById(
            `liveagent_button_offline_${APP_SFBTNID_UNKNOWN}`
          )
        );
        liveagent.showWhenOnline(
          APP_SFBTNID_KNOWN,
          document.getElementById(
            `liveagent_button_online_${APP_SFBTNID_KNOWN}`
          )
        );
      });

      liveagent.addButtonEventHandler(APP_SFBTNID_KNOWN, (e) => {
        if (e === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
          dispatch(showChatLinks({ onlineChat: true }));
        } else if (e === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
          dispatch(showChatLinks({ onlineChat: false }));
        }
      });

      if (
        currentUser &&
        currentUser.firstName &&
        currentUser.lastName &&
        currentUser.id
      ) {
        liveagent.addCustomDetail('User Id', currentUser.id);
        liveagent.addCustomDetail('First Name', currentUser.firstName);
        liveagent.addCustomDetail('Last Name', currentUser.lastName);
        liveagent.addCustomDetail('Case Subject', 'Chat');
        liveagent.addCustomDetail('Case Status', 'New');
        liveagent.addCustomDetail('Case Origin', 'Chat');

        liveagent
          .findOrCreate('Case')
          .map('Subject', 'Case Subject', false, false, true)
          .map('Status', 'Case Status', false, false, true)
          .map('Origin', 'Case Origin', false, false, true)
          .map('SuppliedName', currentUser.fullName, false, false, true)
          .saveToTranscript('CaseId')
          .showOnCreate();

        liveagent
          .findOrCreate('Contact')
          .map('Web_User_Id__c', 'User Id', true, true, false)
          .saveToTranscript('ContactId')
          .linkToEntity('Case', 'ContactId');
      }
    }
    return () => liveagent && liveagent.disconnect();
  }, [dispatch, currentUser, liveagent]);

  return null;
};

export default LiveAgent;
