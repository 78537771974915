import { makeRequestAction } from '.';

export const requestGetPatientList = makeRequestAction('PATIENT_LIST', {
  onSuccess(params, response) {
    return {
      response: {
        patients: {
          list: response,
        },
      },
    };
  },
});

export const requestSaveCart = makeRequestAction('SAVE_CART', {
  onSuccess(params, response) {
    params.cb && params.cb();
    return {
      response: {
        patients: {
          saveCart: response,
        },
      },
    };
  },
});

export const requestPatientInfo = makeRequestAction('PATIENT_INFO', {
  onSuccess(params, response) {
    params.cb && params.cb(response);
    return {
      response: {
        patients: {
          info: response,
        },
      },
    };
  },
});
