import { ADD_ALERT_DATA, CLEAN_ALERT_DATA } from 'actions/actionTypes';

const alertData = () => {
  const initialState = {};

  return (state = initialState, { type, data }) => {
    switch (type) {
      case ADD_ALERT_DATA:
        return {
          ...data,
        };
      case CLEAN_ALERT_DATA:
        return {
          ...initialState,
        };
      default:
        return state;
    }
  };
};

export default alertData();
