import React from 'react';
import { Outlet } from 'react-router-dom';
import { Skeleton } from 'md-styled-components';
import { useSelector } from 'react-redux';
import { getCurrentUserLoading } from 'selectors/users';

const DeductibleChecker = () => {
  const loading = useSelector(getCurrentUserLoading);

  if (loading) {
    return (
      <Skeleton
        align='center'
        paragraph={{
          rows: 8,
          width: ['60', '70', '65', '75', '65', '70', '65', '60'],
        }}
      />
    );
  }

  return <Outlet />;
};

export default DeductibleChecker;
