import { createSelector } from 'reselect';

export const featureFlags = (state) => state.featureFlags;

export const getFlags = createSelector(
  featureFlags,
  (data) => data.flags || {}
);

export const getLoading = createSelector(featureFlags, (data) => data.loading);
