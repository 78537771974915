import { useEffect } from 'react';
import useAxios from 'axios-hooks';
import { getSesStorageItem, setSesStorageItem } from 'utils/storage';
import { sesStorage } from 'constants/index';

const useUserLocation = () => {
  const [, getUserLocation] = useAxios(
    {
      url: '/api/procedurePage/getCustomerLocation',
    },
    { manual: true, autoCancel: false }
  );

  useEffect(() => {
    const sessionItem = getSesStorageItem(sesStorage.locationKey);
    if (!sessionItem) {
      getUserLocation().then(
        ({ data: { data } }) =>
          data && setSesStorageItem(sesStorage.locationKey, data)
      );
    }
  }, [getUserLocation]);
};

export default useUserLocation;
