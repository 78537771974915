import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestGetTabsByUserType } from 'actions/users';
import { getAllItems } from 'selectors/shopping-cart';
import { getCurrentUser, getTabsByUserType } from 'selectors/users';
import { useAlert } from 'contexts/AlertProvider';
import { getCookie, removeCookie } from 'utils/cookieHelper';
import useUserLocation from 'hooks/useUserLocation';
import useRenewCsrf from 'hooks/renewCsrf';
import { isDemo } from '../utils/common';
import { usePrevious } from '../hooks';
import SessionLogout from '../services/SessionLogout';
import './UtmDataSave';
import '../config/mtiFontTrackingCode';

export default React.memo(() => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const shoppingCart = useSelector(getAllItems);
  const tabsByUserType = useSelector(getTabsByUserType);
  const prevCurrentuser = usePrevious(currentUser);
  const { success } = useAlert();

  if (getCookie('openSuccessModal')) {
    removeCookie('openSuccessModal');
    success({
      title: 'Success!',
      text: 'You are now signed in to MDsave.',
      footerText: 'Got it',
    });
  }

  useEffect(() => {
    if (
      !prevCurrentuser?.userType &&
      currentUser?.userType &&
      tabsByUserType === null
    ) {
      dispatch(
        requestGetTabsByUserType.request({
          userType: currentUser?.userType,
        })
      );
    }
  });

  useUserLocation();
  useRenewCsrf();

  return (
    !isDemo && (
      <SessionLogout shoppingCart={shoppingCart} currentUser={currentUser} />
    )
  );
});
