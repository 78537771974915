import {
  requestSuggestedProcedures,
  clearSuggestedProcedures,
  switchClearSuggestedProcedures,
  requestSuggestedProceduresHeader,
  clearSuggestedProceduresHeader,
  requestMDSaveAverage,
  requestProcedure,
} from 'actions/procedures';

export default (
  state = {
    suggestedProcedures: null,
    suggestedProceduresHeader: null,
    averageOfMDSave: null,
    procedure: [],
    clear: false,
  },
  action
) => {
  switch (action.type) {
    case requestSuggestedProcedures.requestTypes.SUCCESS:
      return {
        ...state,
        suggestedProcedures: action.suggestedProcedures,
        clear: false,
      };
    case requestSuggestedProceduresHeader.requestTypes.SUCCESS:
      return {
        ...state,
        suggestedProceduresHeader: action.suggestedProceduresHeader,
      };
    case requestMDSaveAverage.requestTypes.SUCCESS:
      return {
        ...state,
        averageOfMDSave: action.averageOfMDSave,
      };
    case requestProcedure.requestTypes.SUCCESS:
      return {
        ...state,
        procedure: action.procedure,
      };
    case clearSuggestedProceduresHeader.actionName:
      return {
        ...state,
        suggestedProceduresHeader: null,
      };
    case clearSuggestedProcedures.actionName:
      return {
        ...state,
        suggestedProcedures: null,
      };
    case switchClearSuggestedProcedures.actionName:
      return {
        ...state,
        clear: true,
      };
    default:
      return state;
  }
};
