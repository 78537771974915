import { makeRequestAction } from './index';

export const requestPrivateHospitalData = makeRequestAction(
  'PRIVATE_HOSPITAL_DATA',
  {
    onSuccess(params, response) {
      return {
        response: {
          privateHospitalData: {
            allData: response?.data,
          },
        },
      };
    },
  }
);
