import { makeAction, makeRequestAction } from '.';

export const requestSuggestedProviderHospitals = makeRequestAction(
  'SUGGESTED_PROVIDER_HOSPITALS',
  {
    onSuccess(params, response) {
      return {
        suggestedProviderHospitals: response,
      };
    },
  }
);

export const clearSuggestedProviderHospitals = makeAction(
  'CLEAR_SUGGESTED_PROVIDER_HOSPITALS'
);
