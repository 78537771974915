import { requestRegisterPatient, clearPatient } from 'actions/sign-up';

export default (
  state = {
    patient: null,
  },
  action
) => {
  switch (action.type) {
    case requestRegisterPatient.requestTypes.SUCCESS:
      return {
        ...state,
        patient: {
          ...state.patient,
          ...action.patient,
        },
      };
    case clearPatient.actionName:
      return {
        ...state,
        patient: null,
      };
    default:
      return state;
  }
};
