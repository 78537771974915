import {
  requestSuggestedProviders,
  clearSuggestedProviders,
} from 'actions/providers';

export default (
  state = {
    suggestedProviders: null,
  },
  action
) => {
  switch (action.type) {
    case requestSuggestedProviders.requestTypes.SUCCESS:
      return {
        ...state,
        suggestedProviders: action.suggestedProviders,
      };
    case clearSuggestedProviders.actionName:
      return {
        ...state,
        suggestedProviders: null,
      };
    default:
      return state;
  }
};
