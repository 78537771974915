import client from './client';

export const getTransparencyApi = ({ params }) =>
  client().post('/api/transparency/getProcedures', params);

export const hasWithoutInsuranceApi = ({ params }) =>
  client().post('/api/transparency/hasDiscountedProcedures', params);

export const getInsuranceProvidersApi = ({ params }) =>
  client().post('/api/transparency/getInsuranceProviders', params);

export const getProcedureNameApi = ({ params }) =>
  client().post('/api/transparency/getProcedureName', params);

export const getProviderNameApi = ({ params }) =>
  client().post('/api/transparency/getProviderName', params);

export const getPriceUpdateDateApi = ({ params }) =>
  client().post('/api/transparency/getPriceUpdateDate', params);

export const getTransparencyDetailsApi = ({ params }) =>
  client().post('/api/transparency/getProcedureDetails', {
    ...((params && params.params) || {}),
  });
