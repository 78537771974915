import { requestMessages } from 'actions/messages';

export default (
  state = {
    messages: [],
  },
  action
) => {
  if (action.type === requestMessages.requestTypes.SUCCESS) {
    return {
      ...state,
      messages: action.messages,
    };
  }
  return state;
};
