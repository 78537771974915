import { makeRequestAction } from '.';

export const requestProvidersLocations = makeRequestAction(
  'PROCEDURE_PROVIDERS_LOCATIONS',
  {
    onSuccess(params, response) {
      return {
        providersLocations: response.data,
      };
    },
  }
);
