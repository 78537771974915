import { actionTypes } from 'components/ReportTable/constants';
import { makeAction, makeRequestAction, action } from './index';
import { checkNotFoundPage } from '../../utils/checkNotFoundPage';

const { SET_ELECTRONIC_STATUS, CLEAR_ELECTRONIC_STATUS } = actionTypes;

export const setElectronicLinkData = (data) =>
  action(SET_ELECTRONIC_STATUS, data);
export const clearElectronicLinkData = makeAction(CLEAR_ELECTRONIC_STATUS);

export const requestGetFeaturedSpecialties = makeRequestAction(
  'FEATURED_SPECIALTIES',
  {
    onSuccess(params, { data }) {
      const colors = ['red', 'gold', 'primary-cta', 'pink-gradient-end'];
      data = data.map((item, index) => ({ ...item, color: colors[index] }));
      return {
        response: {
          featuredSpecialties: data,
        },
      };
    },
  }
);

export const clearGetFeaturedSpecialties = makeAction(
  'CLEAR_FEATURED_SPECIALTIES'
);

export const requestSystemLocations = makeRequestAction(
  'GET_SYSTEM_LOCATIONS',
  {
    onSuccess(params, response) {
      checkNotFoundPage(response);
      return {
        systemLocations: response,
      };
    },
  }
);

export const clearSystemLocations = makeAction('CLEAR_SYSTEM_LOCATIONS');

export const requestGetGroupsMapData = makeRequestAction('GROUPS_MAP_DATA', {
  onSuccess(params, response) {
    return {
      response: {
        groupsMapData: response?.data,
      },
    };
  },
});

export const requestGetImageFullPath = makeRequestAction(
  'GET_IMAGE_FULL_PATH',
  {
    onSuccess(params, response) {
      return {
        [params.src]: response,
        src: params.src,
      };
    },
  }
);

export const requestGetAllSpecialties = makeRequestAction('ALL_SPECIALITIES', {
  onSuccess(params, { data }) {
    return {
      allSpecialties: data,
    };
  },
});

export const requestShareCart = makeRequestAction('SHARE_CART', {
  onSuccess(params, response) {
    params.cb && params.cb({ response });
    return {
      shareCart: response,
    };
  },
});

export const requestGroupUserHospitalList = makeRequestAction(
  'GROUP_USER_HOSPITAL_LIST',
  {
    onSuccess(params, response) {
      return {
        hospitalList: response.data,
      };
    },
  }
);

export const requestGroupUserAllHospitalsApi = makeRequestAction(
  'GROUP_USER_ALL_HOSPITALS',
  {
    onSuccess(params, { data }) {
      return {
        allHospitals: data,
      };
    },
  }
);

export const requestGroupUserAccountManager = makeRequestAction(
  'GROUP_USER_ACCOUNT_MANAGER',
  {
    onSuccess(params, { data }) {
      return {
        accountManager: data,
      };
    },
  }
);

export const requestGroupLogo = makeRequestAction('GROUP_LOGO', {
  onSuccess(params, response) {
    return {
      groupLogo: response,
    };
  },
});

export const clearGroupLogo = makeAction('CLEAR_GROUP_LOGO');
