import { makeRequestAction } from './index';

export const requestEmailPreferences = makeRequestAction('EMAIL_PREFERENCES', {
  onSuccess(params, response) {
    return {
      response: {
        emailPreferences: response.data,
      },
    };
  },
});
