import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  lazy,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import * as Success from './Success.json';
import * as Warning from './Warning.json';
import * as Error from './Error.json';

const AlertModal = lazy(() => import('../../components/Modals/AlertModal'));

const AlertContext = createContext({});

export default React.memo(({ children }) => {
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const status = useCallback((animationData, params = {}) => {
    setVisible(true);
    setData({
      animationData,
      ...params,
    });
  }, []);

  useEffect(() => {
    if (!visible && !isEmpty(data)) {
      setData({});
    }
  }, [visible, data]);

  return (
    <AlertContext.Provider
      value={{
        success: useCallback(
          (params) => status(Success, { alertType: 'success', ...params }),
          [status]
        ),
        warning: useCallback(
          (params) => status(Warning, { alertType: 'warning', ...params }),
          [status]
        ),
        error: useCallback(
          (params) => status(Error, { alertType: 'error', ...params }),
          [status]
        ),
        close: useCallback(() => setVisible(false), []),
      }}
    >
      <React.Suspense fallback={<></>}>
        {data.animationData?.default && (
          <AlertModal open={visible} setVisible={setVisible} {...data} />
        )}
      </React.Suspense>
      {children}
    </AlertContext.Provider>
  );
});

export const useAlert = () => {
  const { warning, success, error, close } = useContext(AlertContext);
  return {
    warning,
    success,
    error,
    close,
  };
};
