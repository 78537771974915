import {
  requestWithoutInsProcDetails,
  requestWithInsProcDetails,
  transparencyProcedureDetailsOpen,
} from 'actions/transparency';
import entitiesMerge from './entitiesMerge';

const setProcDetailsItems = (stateData, action, updateCol = 'items') => {
  const index = stateData.findIndex(
    (procedure) => procedure.procedure_id === action.params.procedureId
  );
  const newInsuranceProcedures = [...stateData];
  newInsuranceProcedures[index][updateCol] = action.response;
  if (updateCol === 'items') {
    newInsuranceProcedures[index].isOpened = true;
  }
  return newInsuranceProcedures;
};

export default (
  state = {
    withoutInsuranceProcedures: null,
    withInsuranceProcedures: null,
    hasWithoutInsurance: false,
    insuranceProviders: null,
    procedureName: null,
    providerName: null,
    priceUpdateDate: null,
  },
  action
) => {
  switch (action.type) {
    case requestWithoutInsProcDetails.requestTypes.SUCCESS: {
      return {
        ...state,
        withoutInsuranceProcedures: [
          ...setProcDetailsItems(state.withoutInsuranceProcedures, action),
        ],
      };
    }
    case requestWithInsProcDetails.requestTypes.SUCCESS: {
      return {
        ...state,
        withInsuranceProcedures: [
          ...setProcDetailsItems(state.withInsuranceProcedures, action),
        ],
      };
    }
    case transparencyProcedureDetailsOpen: {
      const dataToUpdate = action.params.isInsuranceTab
        ? 'withInsuranceProcedures'
        : 'withoutInsuranceProcedures';
      return {
        ...state,
        [dataToUpdate]: [
          ...setProcDetailsItems(state[dataToUpdate], action, 'isOpened'),
        ],
      };
    }
    default:
      return entitiesMerge(state, action, 'transparency');
  }
};
