import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  concat,
} from '@apollo/client';
import { APP_APOLLO_SERVER } from '../config/env-vars';
import { getJWT } from '../api/helpers/tokens';

const httpLink = createHttpLink({
  uri: APP_APOLLO_SERVER,
});

const contextLink = new ApolloLink(async (operation, forward) => {
  const token = await getJWT();
  operation.setContext(({ headers }) => ({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...headers,
    },
  }));
  return forward(operation);
});

export default new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(contextLink, httpLink),
});
