import gql from 'graphql-tag';

export const searchInsuranceProvidersQuery = (params) => gql`
  query ($query: SearchInsuranceProvidersInput!) {
    searchInsuranceProviders(
      query: $query
      pagination: { offset: 0, limit: 50 }
    ) {
      insuranceProviders {
        id
        name
      }
      messages {
        message
      }
    }
  }
`;
export default {
  searchInsuranceProvidersQuery,
};
