import { makeRequestAction } from './index';
import { checkNotFoundPage } from '../../utils/checkNotFoundPage';

export const getMailChimpMemberEmail = makeRequestAction(
  'GET_MAIL_CHIMP_MEMBER_EMAIL',
  {
    onSuccess(params, response) {
      return {
        response: {
          support: {
            mailChimpEmail: response,
          },
        },
      };
    },
  }
);

export const requestPageInstance = makeRequestAction('PAGE_INSTANCE', {
  onSuccess(params, response) {
    checkNotFoundPage(response);
    return {
      response: {
        support: {
          pageInstance: response?.data,
        },
      },
    };
  },
});
