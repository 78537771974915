import { debounce } from 'redux-saga/effects';
import { requestSuggestedHospitals } from 'actions/hospitals';
import { getSuggestedHospitalsApi } from 'services/hospitals';

export const watchLoadSuggestedHospitals = {
  ...requestSuggestedHospitals,
  api: getSuggestedHospitalsApi,
  take: debounce,
  ms: 500,
};
