import { createSelector } from 'reselect';
import { requestPrivateHospitalData } from 'actions/privateHospitalData';
import privateSite from 'constants/privateSite';
import { isPrivateHospital } from 'utils/privateSite';
import createLoadingSelector from './create-loading-selector';

export const privateHospitalData = (state) => state.privateHospitalData;

export const getPrivateHospitalData = createSelector(
  privateHospitalData,
  (data) => data?.allData
);

export const privateHospitalIds = createSelector(
  getPrivateHospitalData,
  (item) => item.privateHospitalIds
);

export const privateCompany = createSelector(
  getPrivateHospitalData,
  (item) => item.portal
);

export const getIsAllHospitals = createSelector(
  getPrivateHospitalData,
  (data) => data?.type === privateSite.ALL_HOSPITALS
);

export const isPrivateNotAllHospital = createSelector(
  getIsAllHospitals,
  (isAllHospital) => isPrivateHospital() && !isAllHospital
);

export const allHospitalInHomePage = (location) =>
  createSelector(getIsAllHospitals, (isAllHospital) => {
    const [, slug] = location.pathname?.split('/') || [];
    return isAllHospital && !slug;
  });

export const isStrada = createSelector(
  privateCompany,
  (item) => item?.id === privateSite.STRADA_ID
);

export const createPrivateHospitalLoading = createLoadingSelector(
  requestPrivateHospitalData.actionName
)();
