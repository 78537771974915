const statusPaid = 'Paid';
const statusUnpaid = 'Unpaid';
const statusOverdue = 'Overdue';
const dates = 'dates';
const status = 'statuses';

const statusesOption = [
  { key: statusPaid, name: statusPaid },
  { key: statusUnpaid, name: statusUnpaid },
  { key: statusOverdue, name: statusOverdue },
];

export default {
  statusPaid,
  statusUnpaid,
  statusOverdue,
  dates,
  status,
  statusesOption,
};
