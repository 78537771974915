export default Object.freeze({
  CMS_USER: 1,
  PATIENT: 2,
  GROUP_USER: 4,
  PORTAL_USER: 6,
  REPORT_USER: 7,
});

export const ACCOUNT_TEAM = 'accountant team';
export const UNREDEEM_MPPR = 'UnredeemMPPR';
export const VOUCHER_DISPUTED = 'VoucherDisputed';
export const CPT_CODE_UPDATE = 'CptCodeUpdate';
export const CMS_USER_EMAIL = 'tinfoil@mdsave.com1';
export const CARE_CREDIT_CMS = 'CareCreditCMS';
export const UNREDEEM_VOUCHER = 'UnredeemVoucher';
export const MANUAL_REDEEM_VOUCHER = 'ManualRedeemVoucher';
export const ALLOW_CHANGE_PARTY = 'AllowChangeParty';
export const DEDUCTIBLE_CHECKER_LOG = 'DeductibleCheckerLog';
export const COVERED_PROCEDURES = 'CoveredProcedures';
export const INVOICE_PAGE = 'InvoicePage';
export const UPDATE_INVOICE_STATUS = 'UpdateInvoiceStatus';
export const PARTY_VALIDATOR = 'PartyValidator';
export const ALLOW_AUTO_REPORT_S3_CUSTOM_FOLDER_NAME =
  'AllowAutoReportS3CustomFolderName';
export const COMPANY_URI = 'CompanyUri';
export const COMPANY_DATA_FEED_API = 'CompanyDataFeedApi';
export const CLAIMS_PROCESSING = 'ClaimsProcessing';
export const MANAGE_ONE_CALL = 'ManageOneCall';
export const CAMPAIGN_MANAGER = 'CampaignManager';
