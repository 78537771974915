import { makeRequestAction, makeAction } from '.';

export const requestForgotPassword = makeRequestAction('FORGOT_PASSWORD', {
  onSuccess(params, response) {
    return {
      response: {
        passwordRecovery: {
          passwordEmailSent: response,
        },
      },
    };
  },
});

export const requestResetPassword = makeRequestAction('RESET_PASSWORD', {
  onSuccess(params, response) {
    response.error && params.cb?.();
    return {
      response: {
        passwordRecovery: {
          resetPasswordStatus: response,
        },
      },
    };
  },
});

export const requestCheckCode = makeRequestAction('CHECK_CODE', {
  onSuccess(params, response) {
    return {
      response: {
        passwordRecovery: {
          haveCode: response.data || false,
        },
      },
    };
  },
});

export const requestGetUserEmail = makeRequestAction('GET_USER_EMAIL', {
  onSuccess(params, response) {
    return {
      response: {
        passwordRecovery: {
          email: response.data?.activationEmail,
        },
      },
    };
  },
});

export const requestPasswordRecovery = makeRequestAction('PASSWORD_RECOVERY', {
  onSuccess() {
    return {
      response: {
        passwordRecovery: {
          isChecked: true,
        },
      },
    };
  },
});

export const requestClearCode = makeRequestAction('CLEAR_CODE', {});

export const requestClearEmail = makeAction('CLEAR_EMAIL');

export const requestClearIsChecked = makeAction('CLEAR_IS_CHECKED');
