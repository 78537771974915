import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from 'services/contentful';

const Category = lazy(() => import('.'));
const Subcategory = lazy(() => import('pages/Subcategory'));

const routes = () => (
  <>
    <Route path='/t/:tilesUri/:secondTilesUri' element={<Subcategory />} />
    <Route
      path='/t/:tilesUri'
      element={
        <ApolloProvider client={client}>
          <Category />
        </ApolloProvider>
      }
    />
  </>
);

export default routes;
