import styled from 'styled-components';
import { Col as col } from 'antd';
import propTypes from 'prop-types';
import themes from '../Themes';

const Col = styled(col)`
  font-family: ${themes['font-sans-roman']};
  font-size: ${themes['font-size-base']};
`;

Col.propTypes = {
  /** the number of cells to offset Col from the left */
  offset: propTypes.number,
  /** raster order, used in flex layout mode */
  order: propTypes.number,
  /** the number of cells that raster is moved to the left */
  pull: propTypes.number,
  /** the number of cells that raster is moved to the right */
  push: propTypes.number,
  /** raster number of cells to occupy, 0 corresponds to display: none */
  span: propTypes.number,
  /** <576px and also default setting, could be a span value or an object containing above props */
  xs: propTypes.oneOfType([propTypes.number, propTypes.object]),
  /** ≥576px, could be a span value or an object containing above props */
  sm: propTypes.oneOfType([propTypes.number, propTypes.object]),
  /** ≥768px, could be a span value or an object containing above props */
  md: propTypes.oneOfType([propTypes.number, propTypes.object]),
  /** ≥992px, could be a span value or an object containing above props */
  lg: propTypes.oneOfType([propTypes.number, propTypes.object]),
  /** ≥1200px, could be a span value or an object containing above props */
  xl: propTypes.oneOfType([propTypes.number, propTypes.object]),
  /** ≥1600px, could be a span value or an object containing above props */
  xxl: propTypes.oneOfType([propTypes.number, propTypes.object]),
};

/** @component */
export default Col;
