import { debounce } from 'redux-saga/effects';
import {
  requestGetPatientList,
  requestSaveCart,
  requestPatientInfo,
} from 'actions/patients';

import {
  getPatientListApi,
  saveForUserApi,
  editPatientInfoApi,
} from 'services/patients';

export const watchLoadGetPatientList = {
  ...requestGetPatientList,
  api: getPatientListApi,
  take: debounce,
  ms: 500,
};

export const watchLoadSaveCart = {
  ...requestSaveCart,
  api: saveForUserApi,
};

export const watchLoadPatientInfo = {
  ...requestPatientInfo,
  api: editPatientInfoApi,
};
