import { ANALYTIC_DATA, CLEAR_ANALYTIC_DATA } from 'actions/actionTypes';
import { clearAnalyticData } from 'actions/analyticData';

const analyticData = () => {
  const initialState = {};

  return (state = initialState, { type, data }) => {
    switch (type) {
      case ANALYTIC_DATA:
        return {
          ...data,
        };
      case CLEAR_ANALYTIC_DATA:
        return {
          ...initialState,
        };
      default:
        return state;
    }
  };
};

export default analyticData();
