import {
  requestProviderReviews,
  requestCurrentProcedure,
  requestProviderInfo,
  clearCurrentProcedure,
  clearProviderReviews,
  clearProviderInfo,
  requestPartyData,
  requestEquivalentProcedures,
} from 'actions/mppx';
import entitiesMerge from './entitiesMerge';

export default (
  state = {
    providerInfo: {},
    currentProcedure: {},
    visitProcedures: null,
    additionalProcedures: [],
    providerReviews: {},
    partyData: {},
    equivalentProcedures: {},
  },
  action
) => {
  const updateState = (stateName) => ({
    ...state,
    [stateName]: {
      ...state[stateName],
      ...action.response.mppx[stateName],
    },
  });
  switch (action.type) {
    case requestCurrentProcedure.requestTypes.SUCCESS: {
      return updateState('currentProcedure');
    }
    case requestPartyData.requestTypes.SUCCESS: {
      return {
        ...state,
        partyData: { ...state.partyData, ...action.response },
      };
    }
    case requestEquivalentProcedures.requestTypes.SUCCESS: {
      return {
        ...state,
        equivalentProcedures: {
          ...state.equivalentProcedures,
          ...action.response,
        },
      };
    }
    case requestProviderInfo.requestTypes.SUCCESS: {
      return updateState('providerInfo');
    }
    case requestProviderReviews.requestTypes.SUCCESS: {
      const { providerReviews, additionalReviews, providerId } =
        action.response;
      const existingReview = state.providerReviews[providerId];
      return {
        ...state,
        providerReviews: {
          ...state.providerReviews,
          ...(additionalReviews
            ? {
                [providerId]: {
                  ...existingReview,
                  answers: [
                    ...existingReview.answers,
                    ...additionalReviews[providerId]?.answers,
                  ],
                },
              }
            : providerReviews),
        },
      };
    }
    case clearCurrentProcedure.actionName:
      return {
        ...state,
        currentProcedure: {},
      };
    case clearProviderReviews.actionName:
      return {
        ...state,
        providerReviews: {},
      };
    case clearProviderInfo.actionName:
      return {
        ...state,
        providerInfo: {},
      };
    default:
      return entitiesMerge(state, action, 'mppx');
  }
};
