import Theme from 'md-styled-components/Themes';

const buttonCommonStyles = (size) => `
  padding-top: ${size}px !important;
  padding-bottom: ${size}px !important;
  padding-right: 32px !important;
  padding-left: 32px !important;
  -webkit-transition: all .2s cubic-bezier(0.65, 0.05, 0.36, 1);
  -ms-transition: all .2s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: all .2s cubic-bezier(0.65, 0.05, 0.36, 1);
  svg {
    margin-right: 6px;
  }
  font-family: ${Theme['font-sans-roman']};
  font-weight: ${Theme['font-weight-xxbold']};
  font-size: ${Theme['font-size-base']} !important;
  line-height: 22px !important;
  border: none;
  height: auto !important;
  white-space: nowrap;
  text-decoration: none;
`;

export default buttonCommonStyles;
