import {
  requestEstimatedPrices,
  requestRegionalProviders,
  requestLocationInfo,
  clearRegionalProcedure,
} from 'actions/regionalProcedure';
import entitiesMerge from './entitiesMerge';
import { requestRegionalProcedure } from '../actions/regionalProcedure';

const initialState = {
  procedureInfo: {},
  locationInfo: {},
  locations: {},
  providers: {},
  allProviders: {},
  estimatedPrices: {},
  error: false,
};
const regionalProcedure = (state = initialState, action) => {
  switch (action.type) {
    case clearRegionalProcedure.actionName:
      return { ...state, ...initialState };
    case requestRegionalProviders.requestTypes.REQUEST:
      return { ...state, procedureInfo: initialState.procedureInfo };
    case requestRegionalProcedure.requestTypes.REQUEST:
      return { ...state, providers: initialState.providers };
    case requestLocationInfo.requestTypes.REQUEST:
      return { ...state, locationInfo: initialState.locationInfo };
    case requestEstimatedPrices.requestTypes.REQUEST:
      return { ...state, estimatedPrices: initialState.estimatedPrices };
    default:
      return entitiesMerge(state, action, 'regionalProcedure');
  }
};

export default regionalProcedure;
