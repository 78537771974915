import { isSafari } from 'react-device-detect';
import { common } from 'constants/index';
import { APTIBLE_APP, APP_DEPLOY_ENVIRONMENT } from '../config/env-vars';

export const isProd = () => APP_DEPLOY_ENVIRONMENT === 'master';

export const isDemo = APTIBLE_APP === 'mdsave-demo';

const createRegExp = (str, opts) =>
  new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '');
export const isBot = () => {
  const { userAgent } = navigator;
  const userAgentRegex = createRegExp`.*bot.*|crawl|google-inspectiontool|slurp|spider|mediapartners|bingpreview|addthis|kinza|liebaofast
  |micromessenger|mb2345browser|archive.org_bot|zh-cn|zh_cn|prerender|daum${'i'}`;
  return userAgent && userAgent.match(userAgentRegex) !== null;
};

export const debounce = (func) => {
  let timer;

  return (event) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 500, event);
  };
};

export const scrollTo = (
  id,
  isRef = true,
  topHeight = null,
  behavior = 'smooth'
) => {
  let el = isRef ? id.current : id;

  if (el.ref) {
    el = el.ref;
  }

  if (el) {
    el.scrollIntoView({
      behavior: topHeight ? 'auto' : 'smooth',
    });

    if (!isRef && topHeight) {
      const calcScroll = window.pageYOffset - topHeight;
      if (isSafari) {
        window.scroll(0, calcScroll);
      } else {
        window.scroll({
          top: calcScroll,
          left: 0,
          behavior,
        });
      }
    }
  }
};

export const searchOnBlur = (
  relatedTarget,
  autocompleteClass,
  setAutocompleteClass
) => {
  const isBtn = (className) =>
    relatedTarget &&
    (relatedTarget.classList.contains(className) ||
      relatedTarget.getElementsByClassName(className).length > 0);

  return (
    setAutocompleteClass &&
    autocompleteClass &&
    !isBtn(common.searchClearBtnClassName) &&
    !isBtn(common.searchBtnClassName) &&
    setAutocompleteClass('')
  );
};

export const addApostrophe = (name) =>
  `${name}’${name.slice(-1) === 's' ? '' : 's'}`;

export const checkTrue = (...props) => !props.some((e) => Boolean(e) === false);
