export default {
  facebook: 'https://www.facebook.com/mdsave',
  instagram: 'https://www.instagram.com/mdsave',
  twitter: 'https://twitter.com/mdsave',
  linkedin: 'https://www.linkedin.com/company/mdsave',
  authorize: 'http://www.authorize.net/',
  bbbPlus:
    'http://www.bbb.org/nashville/business-reviews/consultants-medical/mdsave-in-brentwood-tn-37077520#bbbseal',
  aptible: 'http://www.aptible.com',
  klas: '/media/mdsave-rated-klas-research',
  // eslint-disable-next-line max-len
  soc: 'https://www.vanta.com/collection/soc-2/what-is-soc-2?_gl=1*blmn0t*_up*MQ..&gclid=CjwKCAjwi_exBhA8EiwA_kU1MmpItNH2rTLAjhvi7_8SF3dHMrrrx-St-MXBKIvTeSvhQ52Srg4hFxoCfbMQAvD_BwE',
};
