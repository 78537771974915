import { takeEvery } from 'redux-saga/effects';
import {
  requestOrderInfo,
  requestProviders,
  requestProcedures,
  requestPdfLinks,
  requestPatientInfo,
  requestTransactionAmounts,
} from 'actions/postPurchase';
import {
  getOrderInfo,
  getMedicalProvider,
  getProcedures,
  getPdfLinks,
  getPatientInfo,
  getTransactionAmounts,
} from 'services/postPurchase';

export const watchLoadStepsData = { ...requestOrderInfo, api: getOrderInfo };

export const watchLoadMedicalProviders = {
  ...requestProviders,
  api: getMedicalProvider,
  take: takeEvery,
};

export const watchLoadProcedures = {
  ...requestProcedures,
  api: getProcedures,
  take: takeEvery,
};

export const watchLoadPdfLinks = { ...requestPdfLinks, api: getPdfLinks };

export const watchLoadPatientInfoInOrder = {
  ...requestPatientInfo,
  api: getPatientInfo,
};

export const watchLoadAmounts = {
  ...requestTransactionAmounts,
  api: getTransactionAmounts,
};
