import client from './client';

export const getLocationInfoApi = ({ params }) =>
  client().get(`/location/getLocationInfo${params.searchParams}`);

export const getRegionalProcedureApi = ({ params }) =>
  client().get(`/location/getProcedure${params.searchParams}`);

export const getRegionalLocationsApi = ({ params }) =>
  client().get(`/location/getLocations${params.searchParams}`);

export const getRegionalProvidersApi = ({ params }) =>
  client().get(`/location/getProviders${params.searchParams}`);

export const getAllRegionalProvidersApi = ({ params }) =>
  client().get(`/location/allProviders${params.searchParams}`);

export const getEstimatedPricesApi = ({ params }) =>
  client().post('/location/getRegionalEstimatedPrices', params);
