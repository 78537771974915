import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getCheckRightsApi } from 'services/sign-in';
import { privateRoutes } from 'constants/index';
import { Skeleton } from 'md-styled-components';

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      await getCheckRightsApi(privateRoutes[pathname]);
      setLoading(false);
    })();
  }, [pathname]);

  if (loading) {
    return (
      <Skeleton
        align='center'
        paragraph={{
          rows: 8,
          width: ['60', '70', '65', '75', '65', '70', '65', '60'],
        }}
      />
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
