import { makeRequestAction } from './index';

export const requestNetworkInfo = makeRequestAction('NETWORK_INFO', {
  onSuccess(params, response) {
    return {
      response: {
        networkInfo: response,
      },
    };
  },
});
