import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Button } from 'md-styled-components-v2';
import { useAlert } from 'contexts/AlertProvider';
import { useSelector } from 'react-redux';
import { getIsPatientUser, getIsGuestUser } from 'selectors/users';
import { debounce } from '../utils/common';
import { getLogoutApi } from './logout';
import { redirectToUrl } from './client';
import { clearLocalStorageItem } from '../utils/storage';
import { sesStorage } from '../constants';

const {
  sessionWatchDelay,
  userMinutesToPopup,
  userMinutesToLogout,
  patientMinutesToPopup,
  patientMinutesToLogout,
} = sesStorage;

const SessionLogout = (props) => {
  const { currentUser, shoppingCart } = props;
  const watchInterval = useRef(null);
  const isPatient = useSelector(getIsPatientUser);
  const isGuest = useSelector(getIsGuestUser);
  let isActive = false;
  let popup;

  let minutesToPopup = userMinutesToPopup;
  let minutesToLogout = userMinutesToLogout;
  if (isPatient) {
    minutesToPopup = patientMinutesToPopup;
    minutesToLogout = patientMinutesToLogout;
  }

  const { success, close } = useAlert();

  const resetCompareTime = () => {
    const currentTime = dayjs();
    if (!isActive) {
      isActive = true;
      localStorage.setItem('currentTime', currentTime);
    }
    clearInterval(watchInterval.current);
    startInterval(currentTime);
  };

  const startInterval = (compareTime) => {
    watchInterval.current = setInterval(() => {
      const now = dayjs();
      let currentTime = localStorage.getItem('currentTime');
      currentTime =
        new Date(compareTime) < new Date(currentTime)
          ? currentTime
          : compareTime;
      const checkTime = now.diff(currentTime, 'minutes');
      localStorage.setItem('currentTime', currentTime);
      isActive = false;

      switch (true) {
        case checkTime >= minutesToPopup &&
          checkTime < minutesToLogout &&
          !isGuest:
          !popup && showPopup();
          break;

        case checkTime >= minutesToLogout && !isGuest:
          getLogoutApi({ params: { reactApp: true } }).then((response) => {
            if (response.data.success) {
              clearLocalStorageItem('userIdentityKey');
              redirectToUrl(
                response.data.data.redirectUrl,
                response.data.data.externalUrl
              );
            }
          });
          break;
        default:
          break;
      }
    }, sessionWatchDelay);
  };

  const debouncedResetTime = debounce(resetCompareTime);

  const postTimeResetEvents = [
    'mousemove',
    'click',
    'mouseup',
    'mousedown',
    'keydown',
    'keypress',
    'keyup',
    'submit',
    'change',
    'mouseenter',
    'scroll',
    'resize',
    'dblclick',
  ];

  useEffect(() => {
    postTimeResetEvents.forEach((event) =>
      window.addEventListener(event, debouncedResetTime)
    );

    return () => {
      postTimeResetEvents.forEach((event) =>
        window.removeEventListener(event, debouncedResetTime)
      );
    };
  });

  useEffect(() => {
    clearInterval(watchInterval.current);
    if (!isGuest) {
      debouncedResetTime();
    }
  }, [isGuest]);

  const showPopup = () => {
    popup = success({
      title: 'Are You Still There?',
      html: (
        <>
          <p>You have been idle for {minutesToPopup} minutes.</p>
          <p>
            For your security, if you aren't active within the next 5 minutes,
            {currentUser &&
            currentUser.userType &&
            shoppingCart &&
            shoppingCart.quantity > 0
              ? ' your shopping cart will be empty.'
              : " you'll be automatically logged out of this portal."}
          </p>
        </>
      ),
      footerData: (
        <div className='swal2-actions'>
          <Button shape='round' type='primary' onClick={close}>
            I'm Still Here
          </Button>
        </div>
      ),
      onCancel: () => {
        popup = null;
      },
    });
  };

  return null;
};

export default SessionLogout;
