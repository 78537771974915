import Theme from 'md-styled-components/Themes';

export default `
  background-color: transparent;
  padding: 0 !important;
  color: ${Theme['primary-cta']};
  font-family: ${Theme['font-sans-medium']};
  font-weight: ${Theme['font-weight-bold']};
  font-size: ${Theme['font-size-base']};
  
  path {
    fill: ${Theme['primary-cta']} !important;
  }
  
  &:hover {
    background-color: transparent;
    color: ${Theme['mdsave-blue']} !important;
  }
  
  &:focus {
    background-color: transparent;
    color: ${Theme['mdsave-blue']};
  }
  
  &:active {
    background-color: transparent;
    color: ${Theme['mdsave-blue']};
  }
 
  &:disabled, &:disabled:hover {
    background-color: transparent;
    color: ${Theme['mdsave-blue']};
    path {
      fill: ${Theme['mdsave-blue']} !important;
    }
  }
`;
