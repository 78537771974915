const routes = {
  NOT_FOUND_URL: '/404',
  SIGN_IN_URL: '/signin',
  PROCEDURES_PATH: '/procedures',
  PATIENTS_URL: '/patients',
  A_TO_Z_PROCEDURES_URL: '/procedures',
  A_TO_Z_PROVIDERS_URL: '/provider',
  A_TO_Z_HOSPITALS_URL: '/hospitals',
  MEDIA: '/media',
  MEDIA_PRESS_RELEASES: '/media/category/press-release',
  MEDIA_WHITEPAPERS: '/media/category/whitepapers',
  MEDIA_INFOGRAPHICS: '/media/category/infographics',
  MEDIA_CASE_STUDIES: '/media/category/case-studies',
  MEDIA_BLOG_ENTRIES: '/media/category/blog-entries',
  MEDIA_VIDEOS: '/media/category/videos',
};

routes.PROCEDURE_PAGE_URL = `${routes.PROCEDURES_PATH}/:procedureUri/:procedureId`;
routes.REGIONAL_PROCEDURE_STATE = `${routes.PROCEDURES_PATH}/:procedureUri/:procedureId/:state`;
routes.REGIONAL_PROCEDURE_AREA = `${routes.PROCEDURES_PATH}/:procedureUri/:procedureId/:state/:area`;
routes.REGIONAL_PROCEDURE_CITY = `${routes.PROCEDURES_PATH}/:procedureUri/:procedureId/:state/:area/:zipCode`;

export default routes;
