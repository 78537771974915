import { takeEvery } from 'redux-saga/effects';
import {
  requestGetFeaturedSpecialties,
  requestSystemLocations,
  requestGetGroupsMapData,
  requestGetImageFullPath,
  requestGetAllSpecialties,
  requestShareCart,
  requestGroupUserHospitalList,
  requestGroupUserAllHospitalsApi,
  requestGroupUserAccountManager,
  requestGroupLogo,
} from 'actions/groups';
import {
  getFeaturedSpecialties,
  getSystemLocations,
  getMapDataApi,
  getImageFullPathApi,
  getAllSpecialtiesApi,
  shareCartApi,
  groupUserHospitalListApi,
  groupUserAllHospitalsApi,
  groupUserAccountManagerInfoApi,
  groupLogoApi,
} from 'services/groups';

export const watchGetFeaturedSpecialties = {
  ...requestGetFeaturedSpecialties,
  api: getFeaturedSpecialties,
};

export const watch = { ...requestSystemLocations, api: getSystemLocations };

export const watchGetGroupsMapData = {
  ...requestGetGroupsMapData,
  api: getMapDataApi,
};

export const watchGetImageFullPath = {
  ...requestGetImageFullPath,
  api: getImageFullPathApi,
  take: takeEvery,
};

export const watchGetAllSpecialties = {
  ...requestGetAllSpecialties,
  api: getAllSpecialtiesApi,
};

export const watchLoadShareCart = {
  ...requestShareCart,
  api: shareCartApi,
};

export const watchGroupUserHospitalList = {
  ...requestGroupUserHospitalList,
  api: groupUserHospitalListApi,
};

export const watchGroupLogo = {
  ...requestGroupLogo,
  api: groupLogoApi,
};

export const watchGroupUserAccountManagerInfo = {
  ...requestGroupUserAccountManager,
  api: groupUserAccountManagerInfoApi,
};

export const watchGroupUserAllHospitalsApi = {
  ...requestGroupUserAllHospitalsApi,
  api: groupUserAllHospitalsApi,
};
