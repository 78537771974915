import {
  requestGetItems,
  removeItem,
  requestChangeQty,
  requestClearCart,
  requestSetDiscountItem,
  requestPutItem,
  requestSetOpenCart,
  requestRemoveStatus,
  requestClearAddedItemProcedures,
  requestSaveGroupUserData,
  requestSetNote,
  requestClearItems,
} from 'actions/shopping-cart';
import { shoppingCart } from 'constants/index';

export default (
  state = {
    getItems: [],
    discountItem: {
      quantity: null,
      itemId: null,
      itemName: '',
    },
    openCart: false,
    hospitalContactPerson: '',
    hospitalPhoneNumber: '',
  },
  action
) => {
  switch (action.type) {
    case requestGetItems.requestTypes.SUCCESS:
    case removeItem.requestTypes.SUCCESS:
    case requestChangeQty.requestTypes.SUCCESS:
    case requestPutItem.requestTypes.SUCCESS:
      return {
        ...state,
        getItems:
          action.getItems.status !== shoppingCart.TYPE_PROCEDURE
            ? { ...state.getItems, ...action.getItems }
            : action.getItems,
      };
    case requestClearCart.requestTypes.SUCCESS:
    case requestClearItems.actionName:
      return {
        ...state,
        getItems: [],
      };
    case requestSetDiscountItem.actionName:
      return {
        ...state,
        discountItem: action.params,
      };
    case requestRemoveStatus.actionName:
      delete state.getItems.status;
      return {
        ...state,
        getItems: { ...state.getItems },
      };
    case requestClearAddedItemProcedures.actionName:
      return {
        ...state,
        getItems: {
          ...state.getItems,
          frequentlyProcedures: { procedures: [] },
          putItem: false,
        },
      };
    case requestSetOpenCart.actionName:
      return {
        ...state,
        openCart: !!action.params.visibility,
      };
    case requestSaveGroupUserData.requestTypes.SUCCESS:
      return action.hospitalContactPerson
        ? {
            ...state,
            hospitalContactPerson: action.hospitalContactPerson,
            hospitalPhoneNumber: action.hospitalPhoneNumber,
          }
        : { ...state };
    case requestSetNote.requestTypes.SUCCESS:
      return {
        ...state,
        getItems: {
          ...state.getItems,
          note: action.getItems.cart.note,
        },
      };
    default:
      return state;
  }
};
