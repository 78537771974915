import React, { useEffect } from 'react';
import { getChangeCsrfApi } from '../services/changeCsrf';
import { sesStorage } from '../constants';

const { csrfRefreshRate } = sesStorage;

const useRenewCsrf = () => {
  useEffect(() => {
    const csrfInterval = setInterval(() => {
      getChangeCsrfApi();
    }, csrfRefreshRate);
    return () => clearInterval(csrfInterval);
  }, []);
};

export default useRenewCsrf;
