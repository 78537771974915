import { debounce } from 'redux-saga/effects';
import {
  requestWithoutInsuranceProcedures,
  requestWithInsuranceProcedures,
  requestHasWithoutInsurance,
  requestInsuranceProviders,
  requestGetProcedureName,
  requestGetProviderName,
  requestGetPriceUpdateDate,
  requestWithInsProcDetails,
  requestWithoutInsProcDetails,
} from 'actions/transparency';
import {
  getTransparencyApi,
  hasWithoutInsuranceApi,
  getProcedureNameApi,
  getProviderNameApi,
  getPriceUpdateDateApi,
  getTransparencyDetailsApi,
} from 'services/transparency';

import { getInsuranceProvidersApi } from 'api/transparency';

export const watchLoadInsuranceProviders = {
  ...requestInsuranceProviders,
  api: getInsuranceProvidersApi,
  take: debounce,
  ms: 500,
};

export const watchLoadWithoutInsurance = {
  ...requestWithoutInsuranceProcedures,
  api: getTransparencyApi,
};

export const watchLoadWithInsurance = {
  ...requestWithInsuranceProcedures,
  api: getTransparencyApi,
};

export const watchHasWithoutInsurance = {
  ...requestHasWithoutInsurance,
  api: hasWithoutInsuranceApi,
};

export const watchLoadGetProcedureName = {
  ...requestGetProcedureName,
  api: getProcedureNameApi,
};

export const watchLoadGetProviderName = {
  ...requestGetProviderName,
  api: getProviderNameApi,
};

export const watchPriceUpdateDate = {
  ...requestGetPriceUpdateDate,
  api: getPriceUpdateDateApi,
};

export const watchWithoutInsuranceDetails = {
  ...requestWithoutInsProcDetails,
  api: getTransparencyDetailsApi,
};

export const watchWithInsuranceDetails = {
  ...requestWithInsProcDetails,
  api: getTransparencyDetailsApi,
};
