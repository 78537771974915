import { debounce } from 'redux-saga/effects';
import { requestSuggestedProviders } from 'actions/providers';
import { getSuggestedProvidersApi } from 'services/providers';

// Suggested providers

export const watchLoadSuggestedProviders = {
  ...requestSuggestedProviders,
  api: getSuggestedProvidersApi,
  take: debounce,
  ms: 500,
};
