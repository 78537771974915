import { requestProvidersLocations } from 'actions/location';
import {
  getSuggestedLocationsApi,
  getProvidersLocations,
} from 'services/location';

export const watchProviderLocations = {
  ...requestProvidersLocations,
  api: getProvidersLocations,
};
