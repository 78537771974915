import { makeRequestAction } from '.';

export const requestActivateAccount = makeRequestAction('ACTIVATE_ACCOUNT', {
  onSuccess(params, response) {
    return {
      response: {
        users: {
          activateAccount: response,
        },
      },
    };
  },
});
