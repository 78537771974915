import { makeAction, makeRequestAction } from '.';

// Suggested Providers
export const requestSuggestedProviders = makeRequestAction(
  'SUGGESTED_PROVIDERS',
  {
    onSuccess(params, response) {
      return {
        suggestedProviders: response.data,
      };
    },
  }
);

export const clearSuggestedProviders = makeAction('CLEAR_SUGGESTED_PROVIDERS');
