import { all, fork } from 'redux-saga/effects';
import { makeSaga } from 'config/sagas';
import * as shoppingCart from './shopping-cart';
import * as location from './location';
import * as procedures from './procedures';
import * as topProcedures from './topProcedures';
import * as providers from './providers';
import * as providerHospitals from './providerHospitals';
import * as patients from './patients';
import * as users from './users';
import * as support from './support';
import * as messages from './messages';
import * as groups from './groups';
import * as signUp from './sign-up';
import * as hospitals from './hospitals';
import * as passwordRecovery from './passwordRecovery';
import * as activateAccount from './activateAccount';
import * as mppx from './mppx';
import * as privateHospital from './privateHospitalData';
import * as campaign from './campaign';
import * as geoLocation from './geographicLocation';
import * as transparency from './transparency';
import * as networkInfo from './networkInfo';
import * as carePackage from './carePackage';
import * as emailPreferences from './emailPreferences';
import * as postPurchase from './postPurchase';
import * as regionalProcedure from './regionalProcedure';

const combinedSagas = {
  ...shoppingCart,
  ...location,
  ...procedures,
  ...topProcedures,
  ...providers,
  ...providerHospitals,
  ...patients,
  ...users,
  ...support,
  ...messages,
  ...groups,
  ...signUp,
  ...hospitals,
  ...passwordRecovery,
  ...activateAccount,
  ...mppx,
  ...privateHospital,
  ...campaign,
  ...geoLocation,
  ...transparency,
  ...networkInfo,
  ...carePackage,
  ...emailPreferences,
  ...postPurchase,
  ...regionalProcedure,
};

export default function* rootSaga() {
  yield all(Object.values(combinedSagas).map((i) => fork(makeSaga(i))));
}
