import { makeRequestAction } from './index';

export const requestCurrentUser = makeRequestAction('CURRENT_USER', {
  onSuccess(params, response) {
    if (response.sameUser) {
      return {};
    }
    return {
      response: {
        users: {
          currentUser: response,
        },
      },
    };
  },
});

export const requestCompanyUserCompanies = makeRequestAction(
  'COMPANY_USER_COMPANIES',
  {
    onSuccess(params, response) {
      return {
        response: {
          users: {
            companyUserCompanies: response.data,
          },
        },
      };
    },
  }
);

export const requestSubscribe = makeRequestAction('SUBSCRIBE', {
  onSuccess(params, response) {
    return {
      response: {
        users: {
          subscribed: response,
        },
      },
    };
  },
});

export const requestClearSubscribe = makeRequestAction('CLEAR_SUBSCRIBE', {
  onSuccess() {
    return {
      response: {
        users: {
          subscribed: null,
        },
      },
    };
  },
});

export const requestGetTabsByUserType = makeRequestAction('TABS_BY_USER_TYPE', {
  onSuccess(params, response) {
    return {
      response: {
        users: {
          tabsByUserType: response,
        },
      },
    };
  },
});

export const requestCheckUserByPersonalInformation = makeRequestAction(
  'CHECK_USER_BY_PERSONAL_INFORMATION',
  {
    onSuccess(params, response) {
      params.cb && !response.error && params.cb();
      return {
        response: {
          users: {
            checkUserByPersonalInformation: response.error && response,
          },
        },
      };
    },
  }
);
