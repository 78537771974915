import {
  requestClearEmail,
  requestClearIsChecked,
} from 'actions/passwordRecovery';
import entitiesMerge from './entitiesMerge';

export default (
  state = {
    passwordEmailSent: {},
    resetPasswordStatus: null,
    haveCode: true,
    email: '',
    isChecked: false,
  },
  action
) => {
  switch (action.type) {
    case requestClearEmail.actionName: {
      return {
        ...state,
        email: '',
      };
    }
    case requestClearIsChecked.actionName: {
      return {
        ...state,
        isChecked: false,
      };
    }
    default:
      return entitiesMerge(state, action, 'passwordRecovery');
  }
};
