import React from 'react';
import { Skeleton } from 'md-styled-components';

const PageSkeleton = () => (
  <div className='pt--48 pl--24 flex-display flex-direction-column flex-justify-center'>
    <Skeleton
      paragraph={{ rows: 5, width: ['65%', '75%', '70%', '65%', '70%'] }}
      align='center'
    />
    <div className='mt--24 mb--32'>
      <Skeleton align='center' title={{ width: ['50%'] }} paragraph={false} />
    </div>
    <div className='flex-display flex-justify-center skeleton-container skeleton-container--half'>
      <Skeleton
        title={false}
        paragraph={{ rows: 3, width: ['75%', '85%', '80%'] }}
      />
      <Skeleton
        title={false}
        paragraph={{ rows: 3, width: ['75%', '85%', '80%'] }}
      />
    </div>
  </div>
);

export default PageSkeleton;
