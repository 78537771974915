import entitiesMerge from './entitiesMerge';

export default (
  state = {
    oneTimeLogIn: null,
    currentUser: null,
    subscribed: null,
    tabsByUserType: null,
    checkUserByPersonalInformation: null,
    activateAccount: {},
  },
  action
) => entitiesMerge(state, action, 'users');
