import { setCookie, getCookie, removeCookie } from '../utils/cookieHelper';
import { getMailChimpMemberEmailApi } from '../services/support';

(async () => {
  const addFromUrlToCookie = (
    itemName,
    itemValue,
    checkToAddToSession = false
  ) => {
    if (checkToAddToSession) {
      setCookie(itemName, itemValue);
      sessionStorage.setItem(itemName, itemValue);
    }
    const item = getCookie(itemName);
    if (item) {
      if (!document.cookie.includes(itemName)) {
        setCookie(itemName, item);
      }
    } else {
      removeCookie(itemName);
    }
  };
  const searchParams = new URLSearchParams(window.location.search);
  const urlData = Object.fromEntries(searchParams.entries());

  let mailChimpEmail;
  if (urlData.mc_eid) {
    const {
      data: { mailChimpMemberEmail },
    } = await getMailChimpMemberEmailApi({ mc_eid: urlData.mc_eid });
    mailChimpEmail = mailChimpMemberEmail;
  }

  const mcData = [
    {
      name: 'utm_source',
      value: urlData.utm_source,
      check:
        urlData.utm_source &&
        urlData.utm_medium &&
        urlData.utm_medium.toLowerCase().trim() === 'affiliate',
    },
    {
      name: 'mc_tc',
      value: urlData.mc_tc,
      check: !!urlData.mc_tc,
    },
    {
      name: 'mc_cid',
      value: urlData.mc_cid,
      check: !!urlData.mc_cid,
    },
    {
      name: 'mc_eid',
      value: mailChimpEmail,
      check: !!mailChimpEmail,
    },
  ];
  mcData.forEach(({ name, value, check }) => {
    addFromUrlToCookie(name, value, check);
  });
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('coupon');
  sessionStorage.removeItem('couponApplied');
  sessionStorage.removeItem('callIdentifier');
  sessionStorage.removeItem('isDomesticView');
  sessionStorage.removeItem('dontHaveAnEmail');
  sessionStorage.removeItem('userDataCheckout');
  sessionStorage.removeItem('noSalesforceIdentifier');
  sessionStorage.removeItem('patientInfoData');
})();
