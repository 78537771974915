import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { pageRoutes } from 'constants/index';
import { CAMPAIGN_LINKS, FIGHT, HEART_SCREENING } from './_constants';

const Procedure = lazy(() => import('pages/Procedure'));

const RedirectToCampaign = ({ campaign }) => (
  <Navigate to={CAMPAIGN_LINKS[campaign]} replace />
);

const routes = () => (
  <>
    <Route path={FIGHT} element={<RedirectToCampaign campaign={FIGHT} />} />
    <Route
      path={HEART_SCREENING}
      element={<RedirectToCampaign campaign={HEART_SCREENING} />}
    />
    <Route path={pageRoutes.PROCEDURE_PAGE_URL} element={<Procedure />} />
  </>
);

export default routes;
