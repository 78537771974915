import { requestRegisterProvider, clearProvider } from 'actions/sign-up';

export default (
  state = {
    provider: null,
  },
  action
) => {
  switch (action.type) {
    case requestRegisterProvider.requestTypes.SUCCESS:
      return {
        ...state,
        provider: {
          ...state.provider,
          ...action.provider,
        },
      };
    case clearProvider.actionName:
      return {
        ...state,
        provider: null,
      };
    default:
      return state;
  }
};
