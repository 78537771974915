/**
 * @file
 *
 * Rewrites XMLHttpRequest to automatically send CSRF token with it. In theory
 * plays nice with other JavaScript libraries, needs testing though.
 */

// Here are the basic overloaded method definitions
// The wrapper must be set BEFORE onreadystatechange is written to, since
// a bug in ActiveXObject prevents us from properly testing for it.
const msie = document.documentMode;

/* eslint-disable func-names */
if (!(typeof msie != 'undefined' && msie < 10)) {
  (function (open) {
    XMLHttpRequest.prototype.open = function (method, url) {
      this.currentRequestUrl = url;
      this.addEventListener(
        'readystatechange',
        function () {
          if (this.readyState === this.DONE && this.status === 555) {
            window.location.reload();
          }
        },
        false
      );
      // eslint-disable-next-line prefer-rest-params
      open.apply(this, arguments);
    };
  })(XMLHttpRequest.prototype.open);
}
